import { CheckEnv } from '../Utils/CheckEnv';
import * as Analytics from 'expo-firebase-analytics';

export const CheckAnalytics = async (uid) => {
  const DevOrStaging = CheckEnv()
  // if dev or staging, dont' run analytics
  if (DevOrStaging) await Analytics.setAnalyticsCollectionEnabled(false)
  // else set uid
  else await Analytics.setUserId(`${uid}`)
  return 
}
