import React from 'react';
import { View, Text, SafeAreaView,
  TextInput, TouchableOpacity } from 'react-native';

import firebase from 'firebase';
import { AuthStyles } from '../Styles/AuthStyles';

export default class LoginScreen extends React.Component {
  state = {
    email: "",
    password: "",
    errorMessage: null
  }

  handleLogin = () => {
    const {email, password} = this.state

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        const errorCode = error.code
        let errorMsg = error.message
        if (errorCode === 'auth/user-not-found') errorMsg = 'Email is not signed up.'
        if (errorCode === 'auth/invalid-email') errorMsg = 'Not a real email address.'
        if (errorCode === 'auth/wrong-password') errorMsg = 'Password is invalid.'
        this.setState({errorMessage: errorMsg})
      });
  }

  render() {
    return (
      <SafeAreaView style={ AuthStyles.container }> 
          
        <Text style = { AuthStyles.H3 }>
          Welcome back! Log in to Interestly
        </Text>

        <TextInput 
          style = { AuthStyles.textInput }
          autoCapitalize = "none"
          placeholder = "Email"
          placeholderTextColor = "#676767"
          onChangeText = {email => { 
            this.setState({email})
          }}
          value = {this.state.email} 
        />

        <View>
          {this.state.errorMessage && 
            <Text style = { AuthStyles.error }>
              {this.state.errorMessage}
            </Text>
          }
        </View>

        <TextInput 
          style = { AuthStyles.textInput }
          secureTextEntry
          placeholder = "Password"
          placeholderTextColor = "#676767"
          onChangeText = {password => { 
            this.setState({password})
          }}
          value = {this.state.password} 
        /> 

        <TouchableOpacity
          onPress = {() => this.props.navigation.navigate("PwdReset")}
        >
          <Text style = { AuthStyles.bodyLink }>
            Forgot your password?
          </Text> 

        </TouchableOpacity>  

        <TouchableOpacity 
          style = { AuthStyles.button } 
          onPress = {this.handleLogin}
        >
          <Text style = { AuthStyles.buttonText }>
            Login
          </Text> 
        </TouchableOpacity>

        <TouchableOpacity style = { AuthStyles.otherOption }
          onPress = {() => this.props.navigation.navigate("Signup")}
          >
            <Text style = { AuthStyles.otherText }>
            New to Interestly? Sign up here
          </Text>
              
        </TouchableOpacity>

      </SafeAreaView> 
    );
  }
}