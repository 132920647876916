import firebase from 'firebase';
import { CheckEnv } from '../Utils/CheckEnv';

const Config = {
  apiKey: "AIzaSyCK67JfjCB8vC_hANB6gTWJDRApN8_T_9c",
  // databaseURL: "https://expofb-73b89.firebaseio.com",
  projectId: "expofb-73b89",
  storageBucket: "expofb-73b89.appspot.com",
  messagingSenderId: "563016813728",
  appId: "1:563016813728:web:74cb8148049040b8e34dc5",
  measurementId: "G-TJGQ1VE7B9"
};

// check if dev or staging
const DevOrStaging = CheckEnv()
// console.log(DevOrStaging + ' is if dev or staging in firebase.js')

const devDbUrl = "https://expofb-73b89-staging.firebaseio.com" 
const prodDbUrl = "https://expofb-73b89.firebaseio.com"
Config.databaseURL = (DevOrStaging) ? devDbUrl : prodDbUrl

// console.log(Config.databaseURL)
// const testStorageUrl = "expofb-73b89.appspot.com"
// Config.storageBucket = 

const app = firebase.initializeApp(Config)

const db = firebase.database

const auth = firebase.auth

const storage = firebase.storage()

export {db, auth, app, storage}

