import React from 'react';
import { Platform, KeyboardAvoidingView, SafeAreaView, Text, View, 
  TouchableOpacity, ActivityIndicator, StyleSheet, Dimensions, Image } from 'react-native';

import {GiftedChat, Send, Bubble} from "react-native-gifted-chat";
import {HeaderBackButton} from 'react-navigation-stack';

import { db } from "./Libs/Firebase";
import firebase from "firebase";
import Fire from "../Fire";

import { navBar, chatStyles } from './Styles/Styles';

import { uploadChatImage } from './Utils/uploadChatImage'
import { pickImage, uriToBlob, cameraRollPermission, resize } from './Utils/HandleImage';
import { Foundation, FontAwesome5, Ionicons } from '@expo/vector-icons';
import { TidBasedImage } from './Utils/TidBasedImage';

import * as Analytics from 'expo-firebase-analytics';

import { NavigationActions } from 'react-navigation';

// chatscreen window height for web when icebreaker is active
let chatHeightIced
if (Platform.OS === 'web') {
  const windowHeight = Dimensions.get('window').height
  const iceBreakerHeight = 149.4 + 18 //need extra points to view without scrollbar 
  chatHeightIced = windowHeight - 64 - iceBreakerHeight
}

export default class ChatScreen extends React.Component {
  state = {
    email: "",
    displayName: "",  
    earliestMsgId: '',
    isLoadingEarlier: false, // activity indicator
    loadEarlier: false, // show loadEarlier button
    gid: this.props.navigation.getParam('gid'),
    uid: Fire.uid,
    msgIsImage: false, // if image is being uploaded, set true
    uri: null,
    imageUploading: false,
    dupeMsgTest: [],
    profileUri: '' // for avatar profile photo
  }

  static navigationOptions = ({navigation}) => {
    // pass on groupDetails to GroupInfo screen
    const groupDetails = navigation.getParam('group');
    const username = navigation.getParam('username');
    // fetch topic icon for header title
    const group = navigation.getParam('group')
    const gid = navigation.getParam('gid')
    const title = navigation.getParam('topic name')
    let icon = TidBasedImage(group) // image icon by gid
    
    const JoinNavKey = navigation.getParam('navKey')
    const navFromJoin = NavigationActions.back({
      key: JoinNavKey
    });
  
    return {
      headerLeft:
        <HeaderBackButton 
          onPress={()=> {
            navigation.dispatch(navFromJoin)
          }}
          labelVisible={false}
          tintColor={'#22B2B8'}
        />,
      headerTitle: 
        // is inline to be able to navigate
        <View style={chatStyles.headerContainer}>

          <View 
            style = {[
              chatStyles.headerImageContainer, {
              borderColor: `#${gid}CC` } // 8 digit hex. CC = 80% opacity
            ]}
          >
            <Image source = { icon } style = { chatStyles.headerImage }/>
    
          </View>
          
          <TouchableOpacity
              style={chatStyles.headerTitleContainer}
              onPress={() => navigation.navigate("GroupInfo", {
                'groupDetails': groupDetails,
                'username': username,
              })}
            >
              <Text style={chatStyles.headerTitle}>{title}</Text>

          </TouchableOpacity>

        </View>,
      headerRight: 
        <TouchableOpacity
          style = {navBar.headerRight}
          onPress={() => navigation.navigate("GroupInfo", {
            'groupDetails': groupDetails,
            'username': username,
          })}
        >
          <Foundation name='info' size={32} color='#22B2B8'/>
        </TouchableOpacity>
    }
  }

  get user() {
    return {
      _id: Fire.uid,
      name: firebase.auth().currentUser.displayName,
      avatar: this.state.profileUri // use profile photo uri from fetchProfile
    }
  }

  componentDidMount() {
    const uid = Fire.uid

    const dbUserRef = db().ref('users/'+uid)  // fetch profilePhoto uri from db not storage

    // fetch profile photo to send with all msgs. Reverts to initial if none.
    const fetchProfile = async () => {
      // const profileRef = storage.ref('users/'+Fire.uid+'/profile.png')
      try {
        const snap = await dbUserRef.child('avatar').once('value')
        const uri = snap.val()
        // const uri = await profileRef.getDownloadURL()
        if (uri != null) return this.setState({profileUri: uri})
        return null
      } catch (E) {
        console.log(E);
        throw E;
      } 
    }
    fetchProfile()

    const {email, displayName} = firebase.auth().currentUser;

    this.setState({email, displayName});
    
    // moved the message listener and setState from Fire to here
    const gid = this.props.navigation.getParam('gid');
    const topicName = this.props.navigation.getParam('topic name')
    const msgRef = db().ref(`messages/${gid}/`)
    const userRef = db().ref(`users/${uid}/groups/${gid}`)

    let msgCounter = 0 

    // declare get for initial messages to load 
    const get = callback => {
      msgRef.orderByChild('timestamp').limitToLast(15)
      .on('child_added', snapshot => {
        callback(Fire.parse(snapshot))
        
        // counter to track earliestMsgId
        msgCounter++
        if (msgCounter == 1) this.setState({earliestMsgId: snapshot.val().timestamp})
      });
    };

    //calling get function passing in message as callback param)
    get(message => 
      this.setState(previous => ({
        messages: GiftedChat.append(previous.messages, message)
      }))
    );

    // fetch most recent message user has seen... could change to orderByKey
    msgRef.orderByChild('timestamp').limitToLast(1)
    .on('child_added', snap => { // NOTE: same listener for useNewMsgs, except this only listens on this screen
      // write msgID to user's node
      userRef.update( {latestRead: snap.key} )
    })

    // track views of ChatScreen
    Analytics.logEvent('ViewedGroup', {
      topic_name: topicName,
      gid: gid,
    })
  }

  // when pulling down to fetch earlier msgs, fetch then parse and set isloadingEarlier
  loadEarlierMsgs = () => {

    this.setState({isLoadingEarlier: true, loadEarlier: true})

    const gid = this.props.navigation.getParam('gid');
    const msgRef = db().ref(`messages/${gid}/`)

    // define array for prepending
    let msgArr = []
    let msgObj = {}

    // search for the 15 messages before the most recent
    msgRef.orderByChild('timestamp').endAt(this.state.earliestMsgId-1).limitToLast(15)
      .once('value', snap => {

        // WEB: set array for testing if messages are duplicated
        let dedupeArr = []
        let duplicatedMsgs

        // reverse msg order to prepend in order to GiftedChat
        snap.forEach(msgSnap => { 
          msgObj = {[msgSnap.key]: msgSnap.val()}
          msgArr.splice(0, 0, msgObj) 
          if (Platform.OS == 'web') {
            dedupeArr.push(msgObj) // WEB: save dedupeArr tp test next time loadEarlierMsgs called

            // check if this msg is a duplicate of a previously saved msg
            for (let j = 0; j < this.state.dupeMsgTest.length; j++) {
              if (this.state.dupeMsgTest[j][msgSnap.key]) {
                duplicatedMsgs = true
                console.log('message is a duplicate')
                break
              }
            }
          }
          return duplicatedMsgs // cancels forEach if duplicatedMsgs is true
        })

        // WEB: don't load earlier msgs if duplicates
        // if (duplicatedMsgs) return console.log('dont fetch msgs')  

        // split msgArr into key (msgID) and value (msg) for each msg
        for (let i = 0; i < msgArr.length; i++) {
          // get keys as array of 1 from msgArr, then fetch [0]th element 
          let msgKey = Object.keys(msgArr[i])[0] 
          let msgVal = Object.values(msgArr[i])[0] 

          // pass in *both* key and value to parsePrevious
          this.setState(previous => ({
            messages: GiftedChat.prepend(
              previous.messages, Fire.parsePrevious(msgKey, msgVal)
            )
          }))

          // set earliestMsgId for this msg
          if (i == msgArr.length - 1) {
            this.setState({earliestMsgId: msgVal.timestamp}) 
          }
        }
        this.setState({isLoadingEarlier: false, loadEarlier: false,
          dupeMsgTest: dedupeArr // WEB: save dedupeArr to check against if relooped
        })
      })
    }

  // Moved send from Fire to here to easily push gid here. 
  send = messages => {
    const gid = this.props.navigation.getParam('gid');
    const topicName = this.props.navigation.getParam('topic name')
    
    messages.forEach(item => {
      let message = {
        user: this.user,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      }
      // if image, add image uri to message
      const imageProp = {image: messages[0].image}
      if (this.state.msgIsImage) message = {...message, ...imageProp}

      if (item.text) message = {...message, text: item.text}

      firebase.database().ref("messages/"+gid+"/").push(message)
      // turn off message state flags
      this.setState({ msgIsImage: false, uri: null, imageUploading: false })
    })

    // track messages sent
    Analytics.logEvent('MsgSent', {
      topic_name : topicName,
      gid: gid,
    })
  }

  // select image, resize if large, then upload to Storage and send to db
  chooseAndFetchImage = async () => {
    cameraRollPermission()
    const image = await pickImage()
    if (image != undefined) this.setState({imageUploading: true})

    const uri = await resize(image)
    const blob = await uriToBlob(uri)
    const remoteUri = await uploadChatImage(blob, this.state.gid)

    this.setState({msgIsImage: true, uri: remoteUri})
    this.send([{image: remoteUri}])
  }

  // image picker in composer bar
  renderImagePicker = (uid, gid) => {
    return (
      <TouchableOpacity style= {chatStyles.imagePicker}
        onPress={() => this.chooseAndFetchImage(uid, gid)}
      >
        <FontAwesome5 name='image' size={32} color='#22B2B8'/>
      </TouchableOpacity>
    )
  }
  
  renderSend = (props) => {
    return (
      <View style = {chatStyles.sendButton}>
        <Send {...props} > 
          <Ionicons name='md-send' size={32} color='#22B2B8' style={{lineHeight: 44}}/>
        </Send>
      </View>
    )
  }

  renderBubble = (props) => {
    return (
			<Bubble {...props}
        wrapperStyle={{
          right: {
            backgroundColor: '#22B2B8',
          }
        }}
      />
    )
  }

  renderChatEmpty = () => {  // can have loading animation while rendering
    return (
      <View style = { chatStyles.loadingMsgs }>
        
        <Text style = { chatStyles.h3 }>
          Loading messages
        </Text>
        <ActivityIndicator size='large'/>

      </View>
    )
  }

  componentWillUnmount() {
    const gid = this.props.navigation.getParam('gid');

    Fire.off();
    firebase.database().ref("messages/" + "/" + gid + "/").off();
  }

  render() {
    const chat = 
      <GiftedChat 
        renderUsernameOnMessage={true}
        messages = {this.state.messages} 
        onSend = {this.send}
        user = {this.user}
        loadEarlier = {this.state.loadEarlier}
        onLoadEarlier = {this.loadEarlierMsgs}
        isLoadingEarlier = {this.state.isLoadingEarlier}
        listViewProps={ // pull down to loadEarlierMsgs if loading 
          (this.state.isLoadingEarlier == true && Platform.OS !== 'web') ? {} : // loading Earlier never ends for web. Loading only if native. 
            {
              onEndReached: this.loadEarlierMsgs.bind(this),
              onEndReachedThreshold: 0.7, // higher number, the sooner the msgs load
            }
        }
        scrollToBottom = {true}
        maxInputLength = {300} 
        renderBubble = {this.renderBubble}
        renderActions={this.renderImagePicker}
        renderSend = {this.renderSend}
        renderChatEmpty = {this.renderChatEmpty}
        textInputProps={ StyleSheet.flatten(chatStyles.textInput) } // causes slight jump down on ios after text entered
        placeholder='Message'
        alwaysShowSend
        onPressAvatar={user => // when avatar pressed
          this.props.navigation.navigate("MemberProfile", {
            'memberName': user.name,
            'memberProfileUri': user.avatar
          }) 
        }
      />;

    if (Platform.OS === 'android') {
      return (
        <KeyboardAvoidingView style = {{flex: 1}}
          behavior = "height"
          keyboardVerticalOffset = {82} // offset of 82 works for *standalone* android to show keyboard. Dev android unaffected.
        >

          {chat}
          
          {this.state.imageUploading &&
            <View style={chatStyles.uploading}>

              <Text style={chatStyles.h3}>
                Sending image 
              </Text>
              <ActivityIndicator size='large'/>

            </View>
          }

        </KeyboardAvoidingView>
      )
    }

    return (
      <View style={{flex: 1}}>

          <SafeAreaView style={[
            chatStyles.chatContainer, 
            // this.state.welMsg && Platform.OS === 'web' ? {height: chatHeightIced } : null 
          ]}>
            {chat}

          </SafeAreaView>

        {this.state.imageUploading &&
          <View style={chatStyles.uploading}>

            <Text style={chatStyles.h3}>
              Sending image
            </Text>
            <ActivityIndicator size='large'/>

          </View>
        }
        
      </View>
    )
  }
}