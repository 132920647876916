import React from 'react';
import { View, Text, TouchableOpacity, SafeAreaView } from 'react-native';
import { WebView } from 'react-native-webview';

import { WebViewStyles, AuthStyles } from '../Styles/AuthStyles';
import { MaterialIcons } from '@expo/vector-icons';

export function TermsWebView({navigation}) {
  return (    
    <SafeAreaView style = { WebViewStyles.container }>

      {NavBar({navigation})}

      <WebView source={{ uri: 'https://interestly.co/terms' }}/>

    </SafeAreaView>
  )
}

export function PrivacyWebView({navigation}) {
  return (    
    <SafeAreaView style = { WebViewStyles.container }>

      {NavBar({navigation})}

      <WebView source={{ uri: 'https://interestly.co/privacy' }}/>

    </SafeAreaView>
  )
}

// dynamically determine which screen to navigate back to
function NavBar({navigation}) {
  const prevScreen = navigation.getParam('prevScreen')

  return (
  <View style={ WebViewStyles.header}>

    <TouchableOpacity 
    style = { WebViewStyles.leftNav }
      onPress={() => navigation.navigate(`${prevScreen}`)}
      >
      <MaterialIcons
        name='arrow-back' size={20} color='#22B2B8' 
      />

      <Text style={ WebViewStyles.title }>Back</Text>

    </TouchableOpacity>

  </View>
  )
}


