import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, FlatList, 
  TouchableOpacity, Image } from 'react-native';

import ContentLoader from "react-native-easy-content-loader";
import { styles, navBar} from './Styles/Styles';

import {UserContext} from '../data/FetchData';
import {useAuthUser} from './Libs/useAuthUser';
import {useMemData} from './Libs/useMemData';
import { useNewMsgs } from './Libs/useNewMsgs';
import { writeUnreads } from './Libs/writeUnreads';

import { dateFormat } from './Utils/dateFormat';
import { GroupData } from './Utils/GroupData';
import { separator } from './Modules/Separator';
import { FontAwesome } from '@expo/vector-icons';
import { TidBasedImage } from './Utils/TidBasedImage';
import { mailToRequest } from './Utils/composeMail';

import { CheckAnalytics } from './Utils/CheckAnalytics';

// need this outside the main component to load navBar initially
GroupList.navigationOptions = ({navigation}) => {
  return {
    headerBackTitle: ' ', // to hide back title on Chatscreen 
    title: 'Interestly',
    headerRight: 
      <TouchableOpacity
        style = {navBar.headerRight}
        onPress={() => navigation.navigate("Profile")}
      >
        <FontAwesome name='user-circle' size={32} color='#22B2B8'/>
      </TouchableOpacity>
  }
}

export function GroupList({navigation}) {

  const authUser = useAuthUser()
  const uid = authUser.user.uid; 
  const displayName = authUser.user.displayName;

  const data = GroupData(uid)

  const activeData = data[0];
  const registeredData = data[1];
  const browseData = data[2];
  
  const memCounts = useMemData() // counts members for ALL groups on render
  const newestMsgs = useNewMsgs(uid) // active listener for new msgs
  const checkUnreads = writeUnreads(uid) // active listener for unread count

  // Analytics.setUserId(`${uid}`) // analytics set uid
  const analytics = CheckAnalytics(uid)

  // triggers writeUnreads() on back button nav
  const [rerender, setRerender] = useState(0)
  useEffect(() => {
    const checkUnreads = navigation.addListener('didFocus', () => {
      setRerender(prev => prev + 1)
    })
    return () => checkUnreads
  }, [memCounts]) // using memCount as dependency to not rerender 25x 

  // render active groups and registered groups flatlists
  const renderActReg = ({item}) => {

    let timest = item.newestTS; 
    let time = dateFormat(new Date(timest)) 
    let unreadDigits = Math.log(item.unreadMsgs) * Math.LOG10E + 1 | 0;

    let icon = TidBasedImage(item) // image icon by gid

    return (
      <View>

        <TouchableOpacity style={ styles.listItem }
          onPress = {() => 
            navigation.navigate("Chat", {
              'topic name': item["topic name"],
              'gid': item.gid,
              'group': item,
              'username': displayName,
            })}
          >

          <View style = { [styles.listImageView, {
            borderColor: `#${item.gid}CC` // 8 digit hex. CC = 80% opacity
            } ]}>

            <Image source = { icon } style = { styles.listImage }/>

          </View>

          <View style = { [styles.separator, styles.listContent] }>

            <View style = { styles.listHeadline } >
              <Text 
                style={ [styles.h4, {flex: 1}] }
                numberOfLines = {1}
              >
                {item["topic name"]}
              </Text>

              <Text style = { styles.time }>
                {time}
              </Text>
            </View>

            <View style= { styles.listBody }>
              <Text 
                style={ styles.subhead }
                numberOfLines = {2}
              >
                {item.newestMsg} 
              </Text>

              {/* hack to show unread circle bkgrd by number of msgs */}
              {item.unreadMsgs != 0 && 
              <View style = {[styles.circle, {width: 22 + (unreadDigits-1)*9}]}>
                <Text style = { styles.unreads }>
                  {item.unreadMsgs}
                </Text>
              </View> }
              
            </View>

          </View>

        </TouchableOpacity>

      </View>
    )
  }

  // render browse topics flatlist
  const renderBrowse = ({item}) => {
    let icon = TidBasedImage(item) // image icon by gid

    return (
      <View>

        <TouchableOpacity 
          style = { [ styles.browseItem] } 
          onPress = {() => {
            // setJoining(true) need to move this to when confirming join
            onPressBrowse(item) 
          }}
        >
          <View style = { [styles.listImageView, styles.listBrowseImageView
              // borderColor: `#${item.gid}99` // 8 digit hex. CC = 80% opacity
              ]}>

            <Image source = { icon } 
            style = { styles.listBrowseImage }
            />

          </View>

          <View style = { [styles.separator, styles.browseContent]}>
            
            <View styles = { styles.browseHeadline } >
              <Text 
                style={ styles.browseH4 } // numberOfLines = {3}
              >
                {item["topic name"]}
              </Text>
            </View>

          </View>
      
        </TouchableOpacity>
      
      </View>
    )
  }

  const onPressBrowse = (item) => {
    navigation.navigate("Join", {
      'topic name': item["topic name"],
      'gid': item.gid,
      'group': item,
      'username': displayName,
      'uid': uid
    })
  }

  // screen preloader 
  if (browseData.length == 0 && activeData.length == 0 && registeredData.length == 0) {
    return (
      <ContentLoader 
        active 
        avatar
        containerStyles={{marginTop: 25}}
        listSize={7} 
        tHeight={20}
        pRows={1}
        pHeight={[16]}
        pWidth={['90%']}
      />
    )
  }

  return (
    // Context consumer wrapper
    <UserContext.Consumer>
      {
        user => 
        <View style={styles.container}> 

          <ScrollView>

            <View style = {styles.listContainer}>

              {activeData.length != 0 && <View>
                <Text style = { styles.h3 }>
                  Active Groups
                </Text>

                <FlatList
                  data = {activeData}
                  renderItem = {(item) => renderActReg(item)}
                  keyExtractor={(item, index) => index.toString()}
                  ListFooterComponent = {separator()}
                />

              </View> 
              }

              {registeredData.length != 0 && <View>
                <Text style = { styles.h3 }>
                  Registered Groups
                </Text>

                <FlatList
                  data = {registeredData}
                  renderItem = {(item) => renderActReg(item)}
                  keyExtractor={(item, index) => index.toString()}
                  ListFooterComponent = {separator()}
                />
              </View> 
              }

              <Text style = { styles.h3 }>
                Browse Topics
              </Text>

              <FlatList
                data = {browseData}
                renderItem = {(item) => renderBrowse(item)}
                keyExtractor={(item, index) => index.toString()}
                ListFooterComponent = {separator()}
              />

              <TouchableOpacity
                onPress= {() => mailToRequest(displayName)}
                style = {{alignSelf: 'center', paddingVertical: 25}}
              >
                <Text 
                  style={{color: "#22B2B8", fontSize: 16}}
                >
                  Suggest more topics
                </Text>
              </TouchableOpacity>
        
            </View>

          </ScrollView>
          
        </View> 
    }
    </UserContext.Consumer>  
  ); 
}