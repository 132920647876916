import React from 'react';
import { Text, View, Image } from 'react-native';

import { settings } from './Styles/Styles';

// has to be set outside so android title shows
MemberProfile.navigationOptions = ({navigation}) => {
  return {
    title: `Member Profile` // member name
  }
}

export default function MemberProfile({navigation}) {

  const memberName = navigation.getParam('memberName')
  const memberProfileUri = navigation.getParam('memberProfileUri')
  
  const renderAvatar = () => {
    return (
      <Image 
        source={{uri: memberProfileUri}}
        style={ settings.profilePhoto } 
      />
    )
  }

  return (
    <View style = { settings.memberProfileContainer }>

      {renderAvatar()}
      
      <Text style = { settings.memberProfileH2 }>
        {memberName}
      </Text>

    </View>
  )
}