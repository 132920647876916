import {useState, useEffect} from 'react';

import {db} from './Firebase';
import {useGids} from './useGids';
// import { SelectedGid } from '../Components/SelectedGid';

// active listener for member changes in user's groups
export const useMemData = () => {

  const [memCounts, setMemCounts] = useState([])

  const gids = useGids() // all gids for the user

  useEffect(() => {
    // save gid + memCounts for all groups
    const fetchMemData = () => {
      if (gids != undefined) { // only fetch member count if gids is ready

        let memCounts = []

        for (let i = 0; i < gids.length; i++) {

          let gidInc = false
          
          db().ref(`groups/${gids[i]}/members`)
          .on('value', snap => {

            let thisCount = snap.numChildren()
            thisCount = (thisCount != 0) ? thisCount : 0
            const addThisCount = () => memCounts.push([gids[i], thisCount])

            if (memCounts.length == 0) addThisCount()
            else { 
              // if e's gid is current gid, remove. Then add current
              memCounts.forEach(e => {
                if (e[0] == gids[i]) {
                  gidInc = true
                  memCounts.shift() 
                  addThisCount() 
                }
              })
              if (gidInc == false) addThisCount()
            }
            
            if (i == gids.length - 1) setMemCounts(memCounts)
          })
        }
      }
    }

    fetchMemData()
    // return () => fetchMemData()

  }, [gids]) // only rerun if gids changes?? not sure about this

  return memCounts

}