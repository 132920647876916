import {useState, useEffect} from 'react';

import {db} from './Firebase';
import {useAuthUser} from './useAuthUser';
import {useMemData} from './useMemData';

// fetch user gids when called or when member counts change
export const useUserGids = () => {

  const [userGids, setUserGids] = useState([])

  const memCounts = useMemData()
  const auth = useAuthUser()
  const uid = auth.user.uid;

  useEffect(() => {
    // save user gids 
    const fetchUserGids = () => {
      if (memCounts != undefined) { // only fetch if memCounts fetched
        
        db().ref('users/'+uid+'/groups').on('value', snap => { 
          
          const userGids = [] // reverts to [] when .on triggered

          const snapObj = (snap.val() != null) ? snap.val() : {}
          const userArr = Object.entries(snapObj); 

          for (let i = 0; i < userArr.length; i++) {
            if (userArr[i][1].status != '0left') {  // group info in element [1]
              userGids.push(userArr[i][0])
            }
            if (i == userArr.length - 1) {
              // console.log(userGids + ' is userGids')
              setUserGids(userGids)
            }
          }
        })
      }
    }

    return () => fetchUserGids()

  }, [memCounts]) // only rerun if memCount changes?? not sure about this

    return userGids

}

