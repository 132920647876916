import React from 'react';
import { Platform, Alert } from 'react-native';

import firebase from 'firebase'
import Fire from '../Fire'

import { Notifications, AppLoading } from 'expo';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';

export const pushCheck = ({navigation}) => {

  // check if push granted on mobile, ask if not, then write to db
  const pushPermission = async () => {

    // if on mobile but not simulator, check for permissions
    if (Platform.OS === 'android' || Platform.OS === 'ios' && Constants.isDevice) {
      
      const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      let finalStatus = existingStatus;

      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
        finalStatus = status;
      }
      // if (finalStatus !== 'granted') {
      //   return notGrantedAlert()
      // }

      // if granted, request push token from Expo
      let token = await Notifications.getExpoPushTokenAsync();

      // save push token to db
      const uid = Fire.uid
      await firebase.database().ref(`users/${uid}/`).update({pushToken: token})
    } 
    // push for web Expo not yet available https://github.com/expo/expo/issues/6895

    // show notifications in Android 8.0+
    if (Platform.OS === 'android') {
      Notifications.createChannelAndroidAsync('messages', {
        name: 'messages',
        sound: true,
        priority: 'max',
        vibrate: [0, 250, 250, 250],
      });
    }
  }
  
  pushPermission()

  navigation.navigate("Group") // navigate to app

  return (
    <AppLoading/>
  )
}

// const notGrantedAlert = () => {
//   const title = `You can always set up notifications later \n`
//   const iPhoneMsg = `Open the Settings app, tap on Interestly ➡️ Notifications ➡️ Allow Notifications.` 
//   const androidMsg = `Go to your Settings app and find Interestly.`

//   const desc = (Platform.OS === 'ios') ? `${iPhoneMsg}` : `${androidMsg}`

//   return Alert.alert(
//     title,
//     desc,
//     [
//       {
//         text: "OK"
//       }
//     ],
//     { cancelable: false }
//   ) 
// }
