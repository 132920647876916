import firebase from 'firebase';

class Fire {
  constructor() {}
  
  parse = message => {
    const {user, text, timestamp, system, image} = message.val()
    const createdAt = new Date(timestamp);
    // destructured - assign message.key as GiftedChat _id
    const {key: _id} = message; 

    // if system message, return as system
    if (system == true) {
      return {
        createdAt,
        system,
        text,
        user,
        _id
      }
    }
    // if image, return image 
    if (image != undefined) {
      return {
        createdAt,
        image,
        user,
        _id,
      }
    }
    // text messages
    return {
      createdAt,
      text,
      user,
      _id,
    }
  } 

  // input here is from .value, different to Parse() which is from .child_added
  parsePrevious = (msgKey, msgVal) => {
    const {user, text, timestamp, system, image} = msgVal
    const createdAt = new Date(timestamp);
    
    const _id = msgKey; // returning id as msgKey for gifted chat

    // if system message, return as system
    if (system == true) {
      return {
        createdAt,
        system,
        text,
        user,
        _id
      }
    }
    // if image, return image 
    if (image != undefined) {
      return {
        createdAt,
        image,
        user,
        _id,
      }
    }
    // text messages
    return {
      createdAt,
      text,
      user,
      _id,
    }
  } 

  off() { //stop listening to the messages/ ref
    this.db.off()
  }

  get db() {
    return firebase.database().ref("messages");
  }

  get uid() {
    return (firebase.auth().currentUser || {}).uid;
  }
}

export default new Fire();


