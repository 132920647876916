import React, { useState, useEffect } from 'react';
import { Text, View, Image, TouchableOpacity, ScrollView, 
  ActivityIndicator, Platform } from 'react-native';

import { auth, db } from "./Libs/Firebase";
import { useAuthUser } from './Libs/useAuthUser';
import { cameraRollPermission, pickImage, uriToBlob, uploadToStorage
  , resize } from './Utils/HandleImage';

import { styles, settings } from './Styles/Styles';
import { MaterialCommunityIcons, Feather, FontAwesome } from '@expo/vector-icons';
import * as Linking from 'expo-linking';
import { mailToFriend } from './Utils/composeMail';

// has to be set outside so android title shows
UserProfile.navigationOptions = ({navigation}) => ({
  title: 'Profile'
})

export default function UserProfile({navigation}) {

  const [photoUploaded, setPhotoUploaded] = useState(null)
  const [remoteUri, setRemoteUri] = useState('')
  const [uploading, setUploading] = useState(false)
  
  const authUser = useAuthUser()
  const uid = authUser.user.uid
  const username = authUser.user.displayName;
  
  const dbUserRef = db().ref('users/'+uid)  // fetch profilePhoto uri from db not storage

  // fetch image from Cloud Storage, return remoteUri as useEffect
  const fetchImage = async (uid) => {
    // const profileRef = storage.ref('users/'+uid+'/profile.png')
    try {
      // const uri = await profileRef.getDownloadURL()
      const snap = await dbUserRef.child('avatar').once('value')
      const uri = snap.val()
      if (uri != null) {
        setPhotoUploaded(true)
        return setRemoteUri(uri)
      }
      return setPhotoUploaded(false) // no error thrown because no longer calling getDownloadURL 
    } catch (E) {
      setPhotoUploaded(false) // set here as getDownloadURL returns a promise that will throw an error if no url
      console.log(E);
      throw E;
    } 
  }
  useEffect(() => { fetchImage(uid) }, [])

  // change photo, set loading state and return remote uri
  const changePhoto = async (uid) => {
    const remoteUri = await HandleImage(uid)

    if (remoteUri) {
      await dbUserRef.update({ avatar: remoteUri })
      setPhotoUploaded(true)
      setUploading(false)
      return setRemoteUri(remoteUri)
    }
    return setPhotoUploaded(false)
  }

  // handler for uploading images to Firebase
  const HandleImage = async (uid) => {
    cameraRollPermission() 
    const image = await pickImage()
    if (image != undefined) setUploading(true)

    const uri = await resize(image)
    const blob = await uriToBlob(uri)
    const remoteUri = uploadToStorage(uid, blob)

    return remoteUri
  }


  const renderAvatar = () => {
    // loading state for photo
    if (uploading == true) {
      return (
        <View style={ settings.profilePhoto }>
          <Text>
            Loading
          </Text>
          <ActivityIndicator/>
        </View>
      )
    }
    // show default icon if no photo uploaded
    if (photoUploaded == false) {
      return (
        <FontAwesome name='user-circle' size={160} color='#C2C2C2'/>
      )
    }
    else return (
      <Image 
        source={{uri: remoteUri}}
        style={ settings.profilePhoto } 
      />
    )
  }

  const openTermsAndPrivacy = () => {
    if (Platform.OS === 'web') Linking.openURL('https://interestly.co/privacy', '_blank')
    else {
      navigation.navigate("Privacy", {
        'prevScreen': navigation.state.routeName
      })
    }
  }

  const signOutUser = async () => {
    //remove pushToken before logging out
    const pushTokenRef = db().ref('users/'+uid+'/').child('pushToken')
    try {
      await pushTokenRef.remove()
      auth().signOut();
    } catch (E) {
      console.error(E);
      throw E;
    } 
  }

  return (
    <ScrollView>
      <View style = { settings.profileContainer }>

        <TouchableOpacity 
          style = { settings.touchable }
          onPress={() => changePhoto(uid)}
          >
          {renderAvatar()}
          
          <MaterialCommunityIcons style = { settings.editPhoto }
            name='image-plus' size={40} color='#22B2B8' 
          />
        </TouchableOpacity>
        
        <Text style = { settings.settingsH2 }>
          {username}
        </Text>

      </View>

      <View style = { settings.listContainer }>

        <View style = { styles.separator }/>
        <View style = { settings.spacing }/>
        <View style = { styles.separator }/>

        <View style = {settings.listItem}>

          <TouchableOpacity 
            style = { settings.listTouch }
            onPress={() => navigation.navigate("PwdReset", {
              'auth': true
            })}
          >
            <Text style = { settings.h3 }>Reset Password</Text>

            <Feather name='chevron-right' size={20} color='#C2C2C2'
              style = { settings.chevron }
            />
          </TouchableOpacity>

        </View>

        <View style = { styles.separator }/>
        <View style = { settings.spacing }/>
        <View style = { styles.separator }/>

        <View style = {settings.listItem}>

          <TouchableOpacity 
            style = { settings.listTouch }
            onPress={() => mailToFriend()}
          >
            <Text style = { settings.h3 }>Tell a Friend</Text>

            <Feather name='chevron-right' size={20} color='#C2C2C2'
              style = { settings.chevron }
            />
          </TouchableOpacity>

        </View>

        <View style = { styles.separator }/>
        <View style = { settings.spacing }/>
        <View style = { styles.separator }/>

        <View style = {settings.listItem}>

          <TouchableOpacity 
            style = { settings.listTouch }
            onPress = {() => openTermsAndPrivacy()}
          >
            <Text style = { settings.h3 }>Terms and Privacy</Text>

            <Feather name='chevron-right' size={20} color='#C2C2C2'
              style = { settings.chevron }
            />
          </TouchableOpacity>

        </View>

        <View style = { styles.separator }/>
        <View style = { settings.spacing2 }/>
        <View style = { styles.separator }/>

        <View style = {settings.listItem}>

          <TouchableOpacity 
            style = { settings.listTouch }
            onPress = {() => signOutUser()}
          >
            <Text style = { [settings.h3, settings.warning] }>Log Out</Text>
            
          </TouchableOpacity>

        </View>

        <View style = { styles.separator }/>

        <View style = { settings.footer }/>

      </View>    

    </ScrollView>
  )
}