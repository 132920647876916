import React, {useState} from 'react';
import { FlatList, Text, View, TouchableOpacity, Alert, 
  ScrollView, Image, ActivityIndicator, Platform } from 'react-native';
import firebase from "firebase";
import Fire from "../Fire";

import { MemberInfo } from './Utils/MemberInfo';
import { styles, settings, chatStyles } from './Styles/Styles';
import { mailToReport } from './Utils/composeMail';
import { TidBasedImage } from './Utils/TidBasedImage';

import * as Analytics from 'expo-firebase-analytics';
import { separator } from './Modules/Separator';

const db = firebase.database;

export default function GroupInfo({navigation}) {
  
  GroupInfo.navigationOptions = () => ({
    title: 'Group Info'
  })

  const [leaving, setLeaving] = useState(false)

  const groupDetails = navigation.getParam('groupDetails')
  const username = navigation.getParam('username')

  const gid = groupDetails.gid
  const topicName = groupDetails['topic name']

  const memUids = MemberInfo(gid);

  let icon = TidBasedImage(groupDetails) // images by tid

  // When member leaves group, do the following
  const onPressLeave = memUids => {
    
    const uid = Fire.uid; // current uid

    const userRef = db().ref(`users/${uid}/groups/${gid}/`)
    const groupRef = db().ref(`groups/${gid}/members/`)
    const newRef = db().ref(`users/${uid}/groups-left/${gid}/`)

    // const memCount = memUids.length
    let timestamp = db.ServerValue.TIMESTAMP

    // 0) Confirm leave
    const confirmLeave = () => {
      const title = "⚠️ If you leave, you cannot rejoin this group."
      const desc = `You will still be able to join other groups for this topic.`
      const onConfirm = () => {
        setLeaving(true)
        leaveUpdate() // run LeaveUpdate
      }

      const nativeAlert = Alert.alert(
        title,
        desc,
        [
          {
            text: "Cancel",
            style: "cancel" // iOS only. Android shows first button as 'negative' style
          },
          { 
            text: "Leave group", 
            onPress: () => { onConfirm() }
          }
        ],
        { cancelable: false }
      )

      const webAlert = () => {
        const res = window.confirm(`${title}\n${desc}`)
        if (res) onConfirm()
      }
      
      if (Platform.OS === 'web') {
        webAlert()
      }
      else nativeAlert
    }

    // 1) For all users, update status in user node. 
    const uUpdate = () => userRef.update({status: '0left'})

    // 2) Remove status from group node to avoid counting in memCounts
    const gUpdate = () => groupRef.child(uid).remove()

    // 3) moves joinedAt and sets leftAt to groups-left
    const moveGroupLeft = async (userRef, newRef) => { 
        let snap = await userRef.once('value') 
        const joinedAt = snap.val().joinedAt
        await newRef.set({
          joinedAt: joinedAt,
          leftAt: timestamp
        })
        await userRef.set(null)
    }

    // 4) don't show same group to user again (can still see other groups for same topic)
    const hideFromBrowse = () => db().ref(`groups/${gid}/membersLeft`)
      .update({ [uid]: 'uid' })

    // 5) sys message for member leaving if two or more people
    const leaveSysMsg = () => {
      // if (memCount >= 2) {// NOTE: until cap at 5
        const memLeftId = `member_${username}_left`; //message id for leaving

        const memLeftMsg = { 
          user: uid, 
          text: `${username} has left the group`,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          system: true
        }
        db().ref("messages" + "/" + gid + "/" + memLeftId).set(memLeftMsg) 
      // }
    }

    // alert to send once group left
    const leftGroupAlert = () => {
      const title = "✅ You have left this group."

      const nativeAlert = Alert.alert(
        title, '', // blank description
        [{ 
          text: "OK", 
          onPress: () => navigation.navigate('Group') 
        }],
      )

      const webAlert = () => {
        const res = window.confirm(`${title}`)
        if (res) navigation.navigate('Group')
      }

      if (Platform.OS === 'web') {
        webAlert() 
      }
      else nativeAlert
    }

    // Call all the functions above
    const leaveUpdate = async () => {
      await Promise.all([
        uUpdate(),
        gUpdate()
          .catch(err => { 
            console.log('Group update failed! => '); 
            throw err; 
          }),
        moveGroupLeft(userRef, newRef),
        hideFromBrowse(),
        leaveSysMsg(),
      ])
      .then(() => {
        setLeaving(false)
        leftGroupAlert() // navigates back to home
        
        Analytics.logEvent('LeaveGroup', { // log leaveGroup to analytics
          topic_name: `${topicName}`,
          gid: gid,
        })

      }).catch(err => { console.log('Caught error', err); })
    };

    confirmLeave() // alert to confirm if user wants to leave group
  }

  // rendering participants inline as otherwise navigation is hard to implement in another (non class) function
  function renderParticipants({item}) {
    return (
      <View>
        <TouchableOpacity 
          style = { settings.participantContainer }
          onPress={() =>
            navigation.navigate("MemberProfile", {
              'memberName': item.username,
              'memberProfileUri': item.avatar
            })}
        >
  
          <View 
            style = { settings.participantAvatarContainer }
          >
            <Image source = {{ uri: item.avatar }} style = { settings.participantAvatar }/>
  
          </View>
  
          <View style = { settings.participantH3Container }>
  
            <Text style ={ settings.participantH3 }>
              {item.username}
            </Text>
  
          </View>
  
        </TouchableOpacity>
        
        <View style = { settings.participantSeparator }/>
      </View>
    )
  }

  return (
    <View style = { [styles.container, {backgroundColor: '#F6F6F8'}, settings.groupInfoWebContainer] }>
      <ScrollView>

        <View style = { settings.profileContainer }>

          <View style = { [settings.imageView, {
            borderColor: `#${gid}99` // 8 digit hex. CC = 80% opacity
            } ]}>
            <Image source={ icon } style={ settings.topicIcon } />
          </View>

          <Text style = { settings.infoH2 }> {topicName} </Text>
            
        </View>
        
        <View style = { settings.listContainer }>

          <View style = { styles.separator }/>
          <View style = { settings.subHeadContainer }>
            
            <Text style = { settings.subhead }>PARTICIPANTS</Text>

          </View>
          <View style = { styles.separator }/>

          {memUids.length != 0 &&
          <View style = {settings.participantListContainer}>
            <FlatList
              data = {memUids}
              renderItem = {({item}) => renderParticipants({item})}
              keyExtractor={(item, index) => index.toString()}
              ListFooterComponent = {separator()}
            />
          </View>
          }

          { memUids.length == 0 && <ActivityIndicator size = 'small'/> }

          <View style = { settings.spacing }/>
          <View style = { styles.separator }/>

          <View style = { settings.groupInfoListItem }>

            <TouchableOpacity 
              onPress={()=> { onPressLeave(memUids) }}
            >
              <Text style = { [settings.warning, settings.groupInfoListItemText] }>
                Leave Group
              </Text>
            </TouchableOpacity>

          </View>

          <View style = { [styles.separator, settings.sectionSeparator] }/>
          <View style = { settings.spacing }/>
          <View style = { styles.separator }/>

          <View style = { settings.groupInfoListItem }>

            <TouchableOpacity 
              onPress={() => mailToReport(gid)} // pass in gid and send email.. 
            >
              <Text style = { [settings.warning, settings.groupInfoListItemText] }>
                Report Conversation
              </Text>
            </TouchableOpacity>
            
          </View>

          <View style = { styles.separator }/>

          <View style = { settings.footer }/>

        </View>

      </ScrollView>
      
      {leaving == true &&
        <View style={chatStyles.uploading}>

          <Text style={chatStyles.h3}>
            Leaving group
          </Text>
          <ActivityIndicator size='large'/>

        </View>
      }

    </View>
  )
}