import { StyleSheet, Platform } from 'react-native'
import Constants from 'expo-constants';

const statusBarHeight = Constants.statusBarHeight

export const AuthStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Platform.select({ 
      android: statusBarHeight
    }),
    marginHorizontal: 15,
  },
  H3: {
    fontSize: 20,
    textAlign: 'center',
    marginTop: 36,
    marginBottom: 15,
  },
  textInput: {
    marginVertical: 5,
    paddingHorizontal: 12,
    height: 50,
    borderRadius: 5,
    backgroundColor: "#E5E5E5",
  },
  error: {
    marginBottom: 10, 
    fontSize: 14,
    color: "#DD4D47", 
  },
  subHead: {
    fontSize: 13,
    marginTop: 18,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    width: '100%',
    height: 50,
    borderRadius: 5,
    backgroundColor: "#22B2B8",
  },
  buttonText: { 
    color: "#FFF", 
    fontWeight: "bold", 
    fontSize: 16,
  },
  otherOption: {
    alignSelf: "center", 
    marginTop: 10,
    paddingVertical: 20,
  },
  otherText: { 
    color: "#22B2B8", 
    fontSize: 16
  },
  termsContainer: {
    flexDirection: 'row',
    marginTop: 15,
  },
  bodyLink: {
    color: "#22B2B8", 
  }
})

export const WebViewStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Platform.select({ 
      android: statusBarHeight
    }),
  },
  header: {
    height: 50,
    justifyContent: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: 'grey',
  },
  leftNav: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  title: {
    marginLeft: 5,
    fontSize: 16,
    color: '#22B2B8', 
  },
})