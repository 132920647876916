import {useState, useEffect} from 'react';
import { db, storage } from '../Libs/Firebase'

// Fetch info for this group's members including displayName and photo 
export function MemberInfo(gid) {

  const [memUids, setMemUids] = useState([]);  // hook for saving member uids

  const thisGid = gid

  useEffect(() => {
    
    const fetchMemberInfo = () => {
      db().ref('groups/' + thisGid + '/members').once('value')
      // fetch member uids
      .then(function(snapshot) {
        let memArrUnfil = Object.entries(snapshot.val())

        // filter out left members and push uid into memberArr
        let memberArr = []
        for (let i = 0; i < memArrUnfil.length; i++) {
          let status = memArrUnfil[i][1] // status @ [1] element
          if (status != '0left') memberArr.push(memArrUnfil[i][0]) 
        }
        return memberArr
      }) // then fetch displayNames (and photos) for all members
      .then(memberArr => {
        const memberUids = []; // to be used in ItemList

        for (let i = 0; i < memberArr.length; i++) {
          // create object with user props to be pushed to memberUid array
          const thisUserObj = { uid: `${memberArr[i]}` }
          
          const fetchProfile = async () => {
            let memUid = memberArr[i]
            const dbUserRef = db().ref('users/'+memUid).limitToFirst(2)
            try {
              // const uri = await profileRef.getDownloadURL()
              const snap = await dbUserRef.once('value')
              thisUserObj.avatar = snap.val().avatar
              thisUserObj.username = snap.val().displayName
              memberUids.push(thisUserObj)
              return setMemUids(memberUids)
            } catch (E) {
              console.log(E);
              throw E;
            } 
          }
          fetchProfile()
        }
      })
    }
    
    fetchMemberInfo() // if called after return, it will only run when *leaving GroupInfo screen...

  }, []) 
  return memUids;
}

