import * as MailComposer from 'expo-mail-composer';
import { Platform, Alert, ActionSheetIOS } from 'react-native';

export function mailToReport(gid) {
  try{
    if (Platform.OS == 'ios') { // ios mailcomposer only works if logged in to mail. Show alert here instead.
      const title = "Report this conversation"
      const desc = `Please report any conversations that concern you to support@interestly.co`
      const iosAlert = Alert.alert(
        title,
        desc,
        [],// no buttons
        { cancelable: true }
      )
      iosAlert()
    }
    else MailComposer.composeAsync({
      recipients: ['support@interestly.co'],
      subject: `Reporting conversation #${gid}`,
      body: `Hi, \n
I'm reporting this conversation. Please review. \n
Thanks`,
    })
  } 
  catch(err) { console.log(err) }
}

export function mailToFriend() {
  try{
    if (Platform.OS == 'ios') { // ios mailcomposer only works if logged in to mail. Show action sheet instead
      ActionSheetIOS.showShareActionSheetWithOptions(
        {
          url: 'https://www.interestly.co',
          message: `I'm enjoying using an app that helps people join small groups to learn and discuss interests.\n
Get it free here`,
        },
        failureCallback => {
          console.log(failureCallback, "failureCallback");
        },
        (bool, string) => {
          console.log(bool, "bool");
          console.log(string, "string");
        }
      )
    }
    else MailComposer.composeAsync({
      subject: `App for Group Accountability, Support and Discussions`,
      body: `I'm enjoying using an app that helps people join small groups to learn and discuss interests.\n
Get it free at \n
https://www.interestly.co`,
    })
  }
  catch(err) { console.log(err) }
}

export function mailToRequest(displayName) {
  try{
    if (Platform.OS == 'ios') { // ios mailcomposer only works if logged in to mail. Show alert here instead.
      const title = "Suggest more topics"
      const desc = `Tell us which topics you would like us to include next! \n
Email tsung@interestly.co`
      const iosAlert = Alert.alert(
        title,
        desc,
        [],// no buttons
        { cancelable: true }
      )
      iosAlert()
    }
    else MailComposer.composeAsync({
      recipients: ['support@interestly.co'],
      subject: `Suggested topics — ${displayName}`,
    })
  }
  catch(err) { console.log(err) }
}