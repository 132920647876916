import {useState, useEffect} from 'react';

import {db} from './Firebase';
import { useUserGids } from './useUserGids';
import { useAuthUser } from './useAuthUser';

// fetch the latest message for each group for user
export const useLatestMsgs = () => {

  const [latestMsgs, setLatestMsgs] = useState([])

  const uGids = useUserGids()
  const auth = useAuthUser()
  const uid = auth.user.uid;

  // fetch latest unread msg in each of users' groups
  useEffect(() => {
    const fetchLatestMsgs = () => {
  
      // fetch user's most recentl read msg ids
      const userRef = db().ref(`users/${uid}/groups/`)
  
      userRef.on('value', snap => {
        let latestMsgs = []

        snap.forEach(childSnap => {
          let msgId = childSnap.child('latestRead/').val()
          // assign empty msgId for count in writeUnreads
          msgId = (msgId != null) ? msgId : ' ' 
          
          let gid = childSnap.key
  
          latestMsgs.push([gid, msgId])
        })
        setLatestMsgs(latestMsgs)
      })
    }
    return fetchLatestMsgs
  }, [uGids])

  return latestMsgs
}

