import {useState, useEffect, useCallback} from 'react';

import {db} from './Firebase';

// fetch gids from db
export const useGids = () => {

  const [gids, setGids] = useState([])

  const fetchGids = useCallback(() => {

	  db().ref('group-gids/').once('value')
    .then(snap => {
      const gidArr = Object.keys(snap.val())
      return gidArr
    }).then(gidArr => setGids(gidArr))

	}, []);
	
  useEffect(() => fetchGids(), [fetchGids]) // only runs when called

  return gids
}