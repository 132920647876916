import React from 'react';
import { View, Text, TextInput, TouchableOpacity, SafeAreaView, Platform } from 'react-native';

import firebase from 'firebase';
import { AuthStyles } from '../Styles/AuthStyles';

// save username from handleSignup, send to AuthCheck so it can be written
let username
export const saveDisplayName = () => username

export class SignupScreen extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    uid: "", // is this needed?
    errorMessage: null,
    errorUsername: null,
  };

  handleSignup = async () => {
    // check if username is too short
    const nameLength = this.state.name.length
    if (nameLength < 3) {
      const nameTooShort = 'Choose a username with 3 or more letters'
      return this.setState({errorUsername: nameTooShort})
    } 

    const db = firebase.database
    let usernameTaken = false

    // check if username is unique
    let snap = await db().ref('usernames/').once('value')
    snap.forEach(childSnap => {
      if (childSnap.val() == this.state.name) {
        return usernameTaken = true;          
      } 
    })

    if (usernameTaken == true) {
      const nameTaken = 'That username is already taken. Pick another'
      return this.setState({errorUsername: nameTaken})
    } 

    // WEB EARLY ACCESS check if email is from signups
    if (Platform.OS === 'web') {
      let earlyAccess = false

      let webSnap = await db().ref('user-emails/').once('value')
      webSnap.forEach(childSnap => {
        let key = childSnap.key
        // if  email does not include key, set earlyAccee false and return error
        // key in db cannot include . so need to get around this
        if (this.state.email.includes(key)) {
          return earlyAccess = true
        } 
      })

      if (earlyAccess == false) {
        const notEarlyAccess = `To sign up in a browser, please register with your early access signup email. \n
You can also sign up with another email on iOS or Android and then log in on a browser.`
        return this.setState({errorMessage: notEarlyAccess})
      } 
    }
    
    // save username to be called to send to AuthCheck
    username = this.state.name

    firebase.auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .catch(error => this.setState( {errorMessage: error.message} ));
  }

  render() {
    return (
      <SafeAreaView style={ AuthStyles.container }> 

        <Text style = { AuthStyles.H3 }>
          Sign up for Interestly
        </Text> 

        <TextInput 
          style = { AuthStyles.textInput }
          autoCapitalize = "none"
          placeholder = "Email"
          placeholderTextColor = "#676767"
          onChangeText = {email => { 
            this.setState({email})
          }}
          value = {this.state.email} 
        />

        <View>
          {this.state.errorMessage && 
            <Text style = { AuthStyles.error }>
              {this.state.errorMessage}
            </Text>
          }
        </View>

        <TextInput 
          style = { AuthStyles.textInput }
          secureTextEntry
          placeholder = "Password"
          placeholderTextColor = "#676767"
          onChangeText = {password => { 
            this.setState({password})
          }}
          value = {this.state.password} 
        />  
        
        <Text style = { AuthStyles.subHead }>
          USERNAME
        </Text>

        <TextInput 
          style = { AuthStyles.textInput }
          autoCapitalize = "none"
          placeholder = "What should everyone call you?"
          placeholderTextColor = "#676767"
          onChangeText = {name => { 
            this.setState({name})
          }}
          value = {this.state.name} 
        />

        <View>
          {this.state.errorUsername && 
            <Text style = { AuthStyles.error }>
              {this.state.errorUsername}
            </Text>
          }
        </View>

        <TouchableOpacity 
          style = { AuthStyles.button } 
          onPress = {this.handleSignup}
          >  
            <Text style = { AuthStyles.buttonText }>
              Sign Up
            </Text>
              
        </TouchableOpacity>

        <View style = { AuthStyles.termsContainer }>

          <Text>By signing up, you agree to our</Text>
            
          <TouchableOpacity
            onPress = {() => this.props.navigation.navigate("Terms", {
              'prevScreen': this.props.navigation.state.routeName
            })}
            >
              <Text style = { AuthStyles.bodyLink }> Terms </Text>
                
          </TouchableOpacity>
          
          <Text>and</Text>

          <TouchableOpacity
            onPress = {() => this.props.navigation.navigate("Privacy", {
              'prevScreen': this.props.navigation.state.routeName
            })}
            >
              <Text style = { AuthStyles.bodyLink }> Privacy.</Text>
                
          </TouchableOpacity>

        </View>

        <TouchableOpacity style = { AuthStyles.otherOption }
          onPress = {() => this.props.navigation.navigate("Login")}
          >
            <Text style = { AuthStyles.otherText }>
              Already have an account?
            </Text>
              
        </TouchableOpacity>

      </SafeAreaView> 
    );
  }
}