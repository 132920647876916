import React, { useState, useEffect } from 'react';
import { Text, View, TouchableOpacity, ScrollView, Image, 
  Platform, ActivityIndicator } from 'react-native';

import { db } from "./Libs/Firebase";
import { GoalTitle, Expectations } from './Modules/JoinGroupCopy';

import { settings, styles, chatStyles } from './Styles/Styles';
import { TidBasedImage } from './Utils/TidBasedImage';
import { Feather } from '@expo/vector-icons'; 

import * as Analytics from 'expo-firebase-analytics';

JoinGroup.navigationOptions = ({navigation}) => {
  const topicName = navigation.getParam('topic name')
  return {
    headerBackTitle: ' ', // to hide back title on Chatscreen 
    headerTitle:
      <View style={styles.joinHeaderContainer}>
        
        <Text style={styles.joinHeaderTitle}>
          {topicName}
        </Text>
        
      </View>
  }
}

// give user more info before joining
export function JoinGroup({navigation}) {
  const group = navigation.getParam('group')
  const topicName = navigation.getParam('topic name')
  const username = navigation.getParam('username')
  const uid = navigation.getParam('uid')
  const navKey = navigation.state.key // for naving from ChatScreen directly to GroupList

  const icon = TidBasedImage(group) // images by tid

  const [accordianOpen, setAccordianOpen] = useState(false)
  const [joinable, setJoinable] = useState(false) // default value only loads on first render
  const [isJoining, setIsJoining] = useState(false)
  const [joined, setJoined] = useState(false)
  const [prompt, setPrompt] = useState(false) // for showing toast

  // // block user from joining if jioned and navigating back from chat screen
  useEffect(() => {
    const cantJoinTwice = navigation.addListener('didFocus', () => {
      setJoinable(false)
    })
    return () => cantJoinTwice
  }, [])
  
  const openAccordian = () => {
    setAccordianOpen(!accordianOpen)
    setJoinable(!joinable)
    setPrompt(false)
  }

  const chevronDirection = (accordianOpen) ? 'chevron-up' : 'chevron-down'
  const joinButtonColor = (joinable) ? '#22B2B8' : '#494949'

  const navParams = {
    'topic name': group["topic name"],
    'gid': group.gid,
    'group': group,
    'username': username,
    'navKey': navKey
  }

  // hack against iOS lag and a bit of android from too many GroupList rerenders on join
  const delay = () => {
    if (Platform.OS == 'ios') return 3000
    if (Platform.OS == 'android') return 1000
    return 1000 // web
  }

  const onJoinIntent = async group => {
    if (joinable) {
      await HandleJoin(group, username, uid) 
      return setTimeout(() => {
        setIsJoining(false)
        setJoined(true)
        navigation.navigate("Chat", navParams)
      }, delay())
    }
    else return setPrompt(true)
  }

  let timestamp = db.ServerValue.TIMESTAMP

  // top level handler for users joining groups - couldn't navigate from non react component so had to include logic here
  const HandleJoin = async (item, username, uid) => { 

    const gid = item.gid
    let thisCount = 0
    
    const userRef = db().ref(`users/${uid}/groups/${gid}/`)
    const groupRef = db().ref(`groups/${gid}/`)
    const msgRef = db().ref(`messages/${gid}/`)

    // log in analytics
    Analytics.logEvent('JoinGroup', {
      topic_name: `${item["topic name"]}`,
      gid: gid,
    })

    setIsJoining(true)

    try {
      const members = await fetchCount(gid, thisCount)
      const otherUid = members[1][0] // used in otherMemUpdate
      thisCount = members[0]
      if (thisCount <= 4) {
        return writeUpdates(thisCount, otherUid, userRef, 
          groupRef, msgRef, item, uid, gid, username)
        // dateObj = new Date()
        // utcString = dateObj.toUTCString(); 
        // console.log(utcString)
        // console.log('is time just before nav to chat')
      }
      else {
        return console.log("Group is full, can't join")
      }
    }
    catch(err) { console.log('Caught error', err) }
  }

  // fetch count and other uid
  const fetchCount = async (gid, thisCount) => {
    const countRef = db().ref(`groups/${gid}/members`)
    const snap = await countRef.once('value')

    thisCount = snap.numChildren()
    const snapObj = (snap.val() != null) ? snap.val() : {} // need non-null snap
    const otherUidStatus = Object.keys(snapObj) 

    return [thisCount, otherUidStatus]
  }

  // define all updates for join group
  const writeUpdates = (thisCount, otherUid, userRef, groupRef, msgRef, item, uid, gid, username) => {

    // First member
    if (thisCount == 0) {
      memUserUpdate('2registered', userRef, item, gid)
      memGroupUpdate('2registered', uid, groupRef)
    }
    
    // For second member
    if (thisCount == 1) {
      groupOpened(groupRef, item, msgRef)
      memUserUpdate('1active', userRef, item, gid)
      memGroupUpdate('1active', uid, groupRef)
      otherMemUpdate(otherUid, gid, groupRef)
    }
    
    // For 3rd to 5th members
    if (thisCount >= 2 && thisCount <= 4) {
      memUserUpdate('1active', userRef, item, gid)
      memGroupUpdate('1active', uid, groupRef)
    }
    
    // For 1st to 5th joined members, open modal and write user has joined
    if (thisCount <= 4) newMemSys(username, thisCount, msgRef, item) 

    // For 5th joined member, group is full
    if (thisCount == 4) groupFull(groupRef, msgRef)

    // if (thisCount >= 5) 
  }

  // When any member joins, write updates to users node
  const memUserUpdate = (statusUpdate, userRef, item, gid) => {
    userRef.update({
      status: statusUpdate,
      joinedAt: timestamp, // add joinedAt prop for user 
      'gid': gid,
      'tid': item.tid,
      "topic name": item["topic name"],
      welcomeMsg: true
    })
  }

  // When any member joins, write updates to groups node
  const memGroupUpdate = (statusUpdate, uid, groupRef) => {
    groupRef.child(`members/`).update( {[uid]: statusUpdate} )
  }

  // If 2nd member joined group, write group is open + formedAt system message
  const groupOpened = (groupRef, item, msgRef) => {
    const openedMsg = { //sys msg
      user: 'group_opened', 
      text: `This group is now open`,
      'timestamp': timestamp,
      system: true
    }
    
    groupRef.update({
      'status': 'Open',
      'openedAt': timestamp
    }) 
    //note set here, starts w/ ! for unread msg sorting
    msgRef.update({'!group_opened': openedMsg}) 
  }

  // If 2nd member joined group, update status of 1st member to '1active' - may need to move this to cloud function for security
  const otherMemUpdate = (otherUid, gid, groupRef) => {

    // update status in user and groups
    db().ref(`users/${otherUid}/groups/${gid}/`).update({
      status: '1active',
      // updatedAt: timestamp
    })
    groupRef.child(`members/`).update({[otherUid]: '1active'})
    // groupRef.update( {'updatedAt': timestamp} )
  }

  // When any member joins, send system message,. 
  // If 1st member, also write image to db
  const newMemSys = async (username, thisCount, msgRef, item) => {
    const memNum = thisCount + 1
    const memJoinId = `member_${memNum}_${username}_joined`;
    const newMemMsg = { //new member joined
      user: `${memJoinId}`, 
      text: `${username} has joined!`,
      'timestamp': timestamp,
      system: true
    }
    msgRef.push(newMemMsg) // create unique key and write
  }

  // When 5th member joins, sysmsg group is closed, write full status 
  const groupFull = (groupRef, msgRef) => {
    const fullMsg = { //sys msg
      user: 'group_full', 
      text: `Group is full. No one else can can join.`,
      'timestamp': timestamp,
      system: true
    }
    
    groupRef.update({ 'status': 'Full' }) 
    //starts w/ ! for last in unread msg sorting
    msgRef.update({'!group_full': fullMsg}) 
  }

  // customize copy based on if Goal/Habit/Discussion
  const topicType = () => {
    if (topicName.includes('Goal')) return 'Goal'
    if (topicName.includes('Habit')) return 'Habit'
    if (topicName.includes('Discussion')) return 'Discussion'
  }

  // if came back from joining chatscreen, show very basic view
  
  if (joined) {
    return (
      <View style = { styles.container }>
        
        <TouchableOpacity 
          style = { styles.joinedContainer }
          onPress = {() => navigation.navigate("Group")}
        >

          <Text style = { styles.joinedTitle }>
            Group joined!
          </Text>

          <Text style = { styles.joinedTitle }>
            ⬅️ to home screen
          </Text>

        </TouchableOpacity>

      </View>
    )
  }

  return (
    <View style = { styles.container }>
      <ScrollView>

        <View style = { settings.profileContainer }>

          <View>
            <Image source={ icon } style = { styles.largeIcon }/>
          </View>

          <GoalTitle type = {topicType()}/>

        </View>

        <View style =  {styles.joinContainer} >

          <View style = { styles.separator }/>
          
          <TouchableOpacity 
            style = { styles.joinSubHeadContainer }
            onPress = {() => openAccordian()}
          >

              <Feather name={chevronDirection} size={30} color="#22B2B8" 
                style = { styles.joinChevron }
              />

                <Text style = { styles.joinSubHead }>
                  What to expect
                </Text>

          </TouchableOpacity>

          { !accordianOpen && <View style = { [styles.separator, styles.joinWebPadding] }/> }

          { accordianOpen && !joined && 
            <Expectations type = {topicType()}/> 
          }

        </View>

      </ScrollView>

      {/* If toast is open */}
      { prompt &&
        <View style =  { [styles.joinPromptContainer, {backgroundColor: '#494949'}] } >

          <Text style = { styles.joinPromptText }>
            To join, first tap and read "What to expect"
          </Text> 

          <TouchableOpacity 
            style = { styles.joinPromptCTAContainer } 
            onPress = {() => setPrompt(!prompt)}
          >
            <Text style = { styles.joinPromptCTA }>
              OK
            </Text> 

          </TouchableOpacity>

        </View>
      }

      <View style = { styles.joinCTA }>
        <View style = { styles.separator }/>

        <View style =  {styles.joinButtonsContainer} >

          <TouchableOpacity 
            style = { [styles.joinButton, {backgroundColor: joinButtonColor}] } 
            onPress = {() => onJoinIntent(group)}
          >
            <Text style = { styles.joinButtonText }>
              Join Group
            </Text> 
          </TouchableOpacity>

        </View>
        
      </View>

      {isJoining == true &&
        <View style={chatStyles.uploading}>

          <Text style={chatStyles.h3}>
            Joining group
          </Text>
          <ActivityIndicator size='large'/>

        </View>
      }

    </View>
  )
}
