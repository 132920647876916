import React from 'react'
import {View, StyleSheet, Platform} from 'react-native';

// used as GroupList flatList footers
export function separator(){
  return (
    <View style = {line.separator}></View>
  )
}

const line = StyleSheet.create({
  separator: {
    // bottom: 0.5,
    borderBottomColor: '#C2C2C2',
    borderBottomWidth: StyleSheet.hairlineWidth,
    bottom: Platform.select({ 
      web: 1, // hack for removing weird double lining in chrome
      ios: 0.5, // blend with list item border for native
      android: 0.5
    }),
  }
})