import React, {useState, useEffect} from 'react';
import { SafeAreaView, Text, View, TouchableOpacity, Image } from 'react-native';
import firebase from "firebase";
import Fire from "../../Fire";

export default function Onboarding ({navigation}) {

  // Onboarding.navigationOptions = () => ({
  //   title: 'Onboarding',
  // })

  return (
    <View style = {{flex: 1}}>
      <View style = {{marginHorizontal: 10, marginTop: 20, 
        alignItems: 'center',  }}>

        <TouchableOpacity>
          <Text style = {{marginTop: 20, fontSize: 24, color: "#22B2B8"}}>
            Upload profile photo
          </Text>
        </TouchableOpacity>

      </View>
    </View>
  )

}