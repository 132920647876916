import React from 'react';
import firebase from 'firebase'
import {AppLoading} from 'expo';
import {app} from '../Libs/Firebase';

import { saveDisplayName } from './SignupScreen';

app

export default function AuthCheck({navigation}) {

  firebase.auth().onAuthStateChanged(async user => {
    
  if (user) {
    // if user just created account, this new username will be fetched from signupscreen...
    const newUsername = saveDisplayName()
    
    // user is new if newUsername written AND their current displayName is null
    // then update in auth profile db and written to db
    if (newUsername && !user.displayName) {
      await user.updateProfile({ displayName: newUsername }) 

      const userid = user.uid
      const db = firebase.database
      await Promise.all([
        db().ref('users/' + userid).set({displayName: newUsername}),
        db().ref('usernames/').update({[userid]: newUsername})
      ]).catch(err => { console.log('Caught error', err); })
    }
    
    navigation.navigate("pushCheck")
    } else navigation.navigate("Auth")
  });
  
  return (
    <AppLoading/>
  )
}
