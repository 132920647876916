import {db} from './Firebase';
import { useLatestMsgs } from './useLatestMsgs';

// write the number of unread msgs up to LatestMsg 
export const writeUnreads = (uid) => {

  const latestMsgs = useLatestMsgs()
  
  if (latestMsgs.length != 0) {    
    for (let i = 0; i < latestMsgs.length; i++) { // for each group
      const gid = latestMsgs[i][0]
      const msgId = latestMsgs[i][1]

      if (msgId != null) { // can't orderByKey if null
        // count unread msgs since readMsgId, and write to users' group node
        const msgRef = db().ref(`messages/${gid}/`)
        const userRef = db().ref(`users/${uid}/groups/${gid}/`)
        
        // actively listen and count messages since latestMsg, check if user has left
        msgRef.orderByKey().startAt(msgId).limitToLast(99)
        .on('value', snap => { // child_added was not working
          let userLeft 
          let msg
          // if user has left, return null
          snap.forEach(childSnap => {
            msg = childSnap.val()
            if (msg.user == uid) {
              return userLeft = true
            }
          }) 
          if (userLeft) return null //{console.log('user left'); }
          
          // if user didn't leave
          // console.log('user didnt leave')
          let unreadMsgs = snap.numChildren() - 1 // exclude readMsgId
          if (unreadMsgs >= 0 ) { // && ifUserLeft == false only write unread if something there
            userRef.update({"unreadMsgs": unreadMsgs})
          }
        })
      }
    }
  }
}

