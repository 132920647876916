import React from 'react';
import { Text, View } from 'react-native';
import { styles } from '../Styles/Styles';

export const GoalTitle = (props) => {
  let goalTitle
  if (props.type == 'Goal') {
    goalTitle = [`Make progress towards `, 'your goal ', `in this group.`]
  }
  if (props.type == 'Habit') {
    goalTitle = [`Within 2 weeks, kickstart `, 'a habit ', `with this group.`]
  }
  if (props.type == 'Discussion') {
    goalTitle = [`Discuss in `, 'a safe space', `.`]
  }
  return (
    <View style = { styles.joinTitleContainer }>
      <Text style = {{textAlign: 'center'}}> {/* have to textAlign wrapping text element for Android*/} 
        <Text style = { styles.joinTitle }>{goalTitle[0]}</Text>
        <Text style = { [styles.joinTitle, {fontWeight: 'bold'}] }>{goalTitle[1]}</Text>
        <Text style = { styles.joinTitle }>{goalTitle[2]}</Text>
      </Text>
    </View>
  )
}

export const Expectations = (props) => {
  const joinIf = `Join this group if`
  const how = `How the group works`
  const full = `5️⃣ Group is full when 5 members join.`
  const check = `🗓 Members chose how often to check in.`

  let goalExpect
  if (props.type == 'Goal') {
    goalExpect = [
      joinIf,
      `🎯 You want to achieve a topic-related goal.
💬 You are willing to share your goal.
💬 You want to share your progress.`,
      how,
      `${full}
${check}
⏳Suggestion: every 2 days or twice a week.`
    ]
  }
  else if (props.type == 'Habit') {
    goalExpect = [
      joinIf,
      `🎯 You want to change a topic-related habit.
💬 You can share your habit-change plan.
💬 You want to share your progress.`,
      how,
      `${full}
${check}
⏳Suggestion: every day or every two days.`
    ]
  }
  else if (props.type == 'Discussion') {
    goalExpect = [
      joinIf,
      `❓You might have questions about the topic.
💬 You could share your thoughts or ideas.
💬 Tell the group what interests you about it.`,
      how,
      full
    ]
  }
  return (
    <View style = { styles.joinBodyContainer }>
      <Text style = { styles.joinBodyHeader }>{goalExpect[0]}</Text>
      <Text style = { styles.joinBodyText }>{goalExpect[1]}</Text>
      <Text style = { styles.joinBodyHeader }>{goalExpect[2]}</Text>
      <Text style = { styles.joinBodyText }>{goalExpect[3]}</Text>
    </View>
  )
}
