import {useState, useEffect} from 'react';

import firebase from 'firebase';

// This hook will create state which uses a useState callback to 
// determine whether a user session already exists. 
// The callback is a useful way to initialize state with a value 
// only upon the first mount of a component.
export const useAuthUser = () => {

  // if no current user, set to initialize
  const [user, setUser] = useState(() => { 
    const user = firebase.auth().currentUser 
    return { initializing: !user, user, }}
  );

  // when auth changes, user state updated
  function onChange(user) {
    setUser({ initializing: false, user })
  }

  // listen once for if signed in or not
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)

    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, []);
  return user; // return object w/ initializing + current user props
}