import {useState, useEffect} from 'react';

import {db} from './Firebase';
import { useUserGids } from './useUserGids';

// fetch newest user msgs and timestamp, write to members' and group node to rerender groupList
export const useNewMsgs = (uid) => {

  const [newMsgs, setNewMsgs] = useState(null)

  const userGids = useUserGids()

  // if (userGids.length != 0 ) { //&& auth != null only fetch if userGids fetched

  useEffect(() => {
    const fetchNewMsgs = () => {

      for (let i = 0; i < userGids.length; i++) {

        let gid = userGids[i]
        const msgRef = db().ref(`messages/${gid}/`)
        const groupRef = db().ref(`groups/${gid}/`)
        const userRef = db().ref(`users/${uid}/groups/${gid}/`)
        
        // check if user has left, fetch 1) newest msg for each group of the user and 2) write newest msg timestamp to members
        msgRef.orderByChild('timestamp').limitToLast(1)
        .on('child_added', snap => { 
          let msgObj = snap.val()
          const text = msgObj.text
          const image = msgObj.image
          const newestTS = msgObj.timestamp

          // check if system message, set username
          const isSysMsg = (msgObj.system != null) ? true : false
          const uName = (isSysMsg) ? '' : `${msgObj.user.name}: `

          // message content based on if image exists
          const msg = (image) ? '📷 Image' : text
          
          // 2) write newest msg + timestamp to group node
          groupRef.update({
            'newestMsg': `${uName}${msg}`,
            'newestTS': newestTS
          })

          // return if user has just left, else write to user's own node
          if (msgObj.user == uid) return null

          setNewMsgs(
            userRef.update({ 
              'newestMsg': `${uName}${msg}`,
              'newestTS': newestTS
            })
          )
        })
      }
    }
    return fetchNewMsgs
  }, [userGids])

  return newMsgs
}