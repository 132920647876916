// dynamically source local image based on tid
export function TidBasedImage(item) {
  const tid = item.tid

  switch (true) {    
    // fall asleep  
    case (tid == '00001'): return require(`../img/00001.png`)
    
    // lose weight
    case (tid == '00002'): return require(`../img/00002.png`)
    
    // meditate
    case (tid == '00003'): return require(`../img/00003.png`)

    // books / harry potter
    case (tid == '00004'): return require(`../img/00004.png`)

    // screen time
    case (tid == '00005'): return require(`../img/00005.png`)

    // spanish
    case (tid == '00006'): return require(`../img/00006.png`)

    //  calisthenics
    case (tid == '00007'): return require(`../img/00007.png`)

    // intermittent fasting
    case (tid == '00008'): return require(`../img/00008.png`)

    // Daily NYtimes podcast
    case (tid == '00009'): return require(`../img/00009.png`)

    // coding
    case (tid == '00010'): return require(`../img/00010.png`)

    // cooking
    case (tid == '00011'): return require(`../img/00011.png`)

    // learning
    case (tid == '00012'): return require(`../img/00012.png`)

    // antiracism
    case (tid == '00013'): return require(`../img/00013.png`)

    // pre-release!!
    case (tid == '00014'): return require(`../img/00014.png`)

    // general accountability
    case (tid == '00015'): return require(`../img/00015.png`)
  }
}