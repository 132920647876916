import { StyleSheet, Platform, Dimensions } from 'react-native'

let circleDiam = 22 // for unreads
let h4Height = 22
let h4Multiplier = 3.85 // allow dynamic sizing based on font size

// chatscreen window height for web
const windowHeight = (Platform.OS === 'web') ? Dimensions.get('window').height : null
const chatViewHeight = windowHeight - 64 // 64 = navBar height

// chatscreen window width for web
const windowWidth = (Platform.OS === 'web') ? Dimensions.get('window').width : null
const browseContentWidth = windowWidth - 175 // 140 = width of icon + join button

export const navBar = StyleSheet.create({
  headerRight: {
    marginRight: 15,
  }
})

export const settings = StyleSheet.create({
  profileContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: Platform.select ({
      web: 'center'
    }),
    paddingVertical: 30, 
    backgroundColor: 'white',
  },
  listContainer: {
    flex: 1,
    backgroundColor: '#F6F6F8',
  },
  touchable: {
    alignItems: 'center',
  },
  imageView: { // used in userProfile
    alignItems: 'center',
    justifyContent: 'center',
    height: 100, 
    width: 100,
    borderWidth: 1.5,
    borderRadius: 50,
  },
  topicIcon: {
    width: '85%',
    height: '85%',
    borderRadius: 22,
  },
  profilePhoto: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 160, 
    height: 160, 
    borderRadius: 150
  },
  editPhoto: {
    left: 60,
    bottom: 40,
    backgroundColor: 'white',
    marginBottom: Platform.select({
      web: 32, // hack to style username, as left + bottom position adjustments do not work on web
    })
  },
  settingsH2: {
    marginTop: -20,
    fontSize: 22,
  },
  infoH2: {
    textAlign: 'center',
    marginTop: 20,
    fontSize: 22,
  },
  subHeadContainer: {
    flex: 1,  
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    height: 45,
    paddingBottom: 5,
    paddingTop: Platform.select({
      web: 18,
    }) 
  },
  subhead: {
    marginLeft: 15,
    fontSize: 13,
    color: '#666666',
  },
  listItem: {
    justifyContent: 'center',
    paddingLeft: 15,
    backgroundColor: 'white',
  },
  infoListItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 15,
    backgroundColor: 'white',  
  },
  listTouch: {
    flexDirection: 'row',
    alignContent: 'center',
    lineHeight: 25,
    marginVertical: 15,
  },
  h3: {
    flex: 1,
    fontSize: 17,
  },
  infoH3: {
    flex: 1,
    fontSize: 17,
    paddingVertical: 11,
  },
  sectionSeparator: { // hack for line at end of section
    bottom: 0.5,
    // width: 15,
  },
  chevron: {
    flex: 0,
    paddingRight: 15,
  },
  spacing: {
    height: 30,
  },
  spacing2: {
    height: 45,
  },
  warning: {
    color: '#DD4D47',
  },
  footer: {
    height: 1, // show separator on ios
  },
  // view member profile
  memberProfileContainer: {
    flex: 1,
    alignItems: 'center',  
    justifyContent: 'center',
    backgroundColor: 'white',
    marginTop: Platform.select({
      web: 100,
    })
  },
  memberProfileH2: {
    fontSize: 22,
    marginTop: 20, 
    marginBottom: Platform.select({
      ios: 160,
      android: 160
    })
  },

  // groupInfo additional styling
  participantContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 15,
    backgroundColor: 'white',  
    height: 55,
  },
  participantAvatarContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  participantAvatar: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  participantH3Container: {
    justifyContent: 'center',
    marginLeft: 10,
  },
  participantH3: {
    fontSize: 17,
  },
  participantSeparator: {
    marginLeft: 65, // to match alignment with participant names 
    borderBottomColor: "#C2C2C2",
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  groupInfoListItem: {
    justifyContent: 'center',
    paddingLeft: 15,
    backgroundColor: 'white',  
  },
  groupInfoListItemText: {
    fontSize: 17,
    paddingVertical: 11, // needed for mobile
  },
  groupInfoWebContainer: { // web styling to remove footer spacing at bottom
    height: Platform.select({ 
      web: chatViewHeight,
    }),
  } 
})

export const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    flex: 1, 
    // backgroundColor: '#151515', dark mode
  },
  listContainer: {
    marginHorizontal: 15, 
  }, 
  separator: {
    borderBottomColor: "#C2C2C2",
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  sectionSeparator: {
    bottom: 0.5,
    left: -20,
  },
  h3: {
    marginTop: 15,
    fontSize: 20,
    marginBottom: 0,
  },
  listItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listImageView: {
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 55, 
    width: 55,
    borderWidth: 2.5,
    borderRadius: 27,
  },
  listBrowseImageView: {
    borderWidth: 0,
  },
  listImage: {
    width: '85%',
    height: '85%',
    borderRadius: 13,
  },
  listBrowseImage: {
    width: '100%',
    height: '100%',
    borderRadius: 13,
  },
  listContent: {
    flex: 1, 
    justifyContent: 'center',
    height: 80, //h4Multiplier * h4Height,
  },
  listHeadline: {
    flexDirection: 'row', 
    alignItems: 'flex-end',
  },
  h4: {
    lineHeight: h4Height,
    marginRight: 8,
    fontWeight: '600',
    fontSize: 17,
  },
  time: {
    fontSize: 13,
    lineHeight: 20, // center with title text
    color: '#666666',
  },
  listBody: {
    flexDirection: 'row', 
    alignItems: 'flex-start',
    marginRight: 8,
  },
  subhead: {
    flex: 1, 
    marginRight: 8,
    fontSize: 15,
    lineHeight: 20,
    color: '#666666',
  },
  circle: { 
    justifyContent: 'center',
    width: circleDiam,
    height: circleDiam,
    borderRadius: circleDiam / 2,
    backgroundColor: '#22B2B8',
  },
  unreads: {
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 13,
    color: 'white',
  },

  browseItem: {
    flex: 1, 
    flexDirection: 'row', 
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  browseContent: {
    flex: 1, 
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
  },
  browseHeadline: {
    flex: 1, 
    justifyContent: 'flex-start',
  },
  browseH4: {
    lineHeight: h4Height,
    marginRight: 8,
    fontSize: 17,
    width: Platform.select({
      web: browseContentWidth,
    })
  },
  buttonContainer: {
    borderBottomColor: "#C2C2C2",
    borderBottomWidth: StyleSheet.hairlineWidth,
    height: 80,
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 60, 
    height: 40,
    backgroundColor: "#22B2B8",
    borderRadius: 5,
  },
  buttonText: {
    fontWeight: '600',
    fontSize: 15,
    lineHeight: 20,
    color: "white",
  },
  
  // joinGroup styles
  joinHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  joinHeaderTitle: {
    flex: 1, flexWrap: 'wrap',
    fontSize: Platform.select({
      ios: 16,
      android: 16
    }),
    fontWeight: 'bold',
    color: '#333333',
    // get title centered on Android
    marginRight: Platform.select({
      android: 50
    }),
    paddingHorizontal: Platform.select({
      android: 20
    }),
    textAlign: 'center',
  },
  largeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    height: 150, 
    width: 150,
    resizeMode: 'contain',
    borderRadius: 22,
  }, 
  joinTitleContainer: {
    marginTop: 30,
    marginBottom: 10,
    marginHorizontal: 25,
    justifyContent: 'center',
  },
  joinTitle: {
    fontSize: 20,
    color: '#666666',
  },
  joinContainer: {
    flex: 1,
    marginHorizontal: 15,
  },
  joinSubHeadContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  joinSubHead: {
    flex: 1,
    paddingVertical: 16,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#22B2B8',
  },
  joinChevron: {
    flex: 0,
    marginRight: 5,
  },
  joinBodyContainer: {
    marginBottom: 20,
  },
  joinBodyHeader:{
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 30,
  },
  joinBodyText: {
    fontSize: 16, 
    lineHeight: 35,
    marginBottom: 25,
  },
  joinWebPadding: {
    marginBottom: Platform.select ({
      web: 100, 
    }),
  },
  joinCTA: {},
  joinButtonsContainer: {
    flexDirection: 'row',
    justifyContent: "space-around",
    marginVertical: 25,
  },
  joinButton: {
    alignItems: "center",
    justifyContent: "center",
    width: '85%',
    height: 50,
    borderRadius: 8,
  },
  joinButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  joinPromptContainer: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingHorizontal: 15,
    paddingVertical: 15,
    marginHorizontal: 15,
    marginBottom: 15,
    borderRadius: 5,
    // backgroundColor: '#494949',
  },
  joinPromptText: {
    fontSize: 14,
    color: '#ffffff',
  },
  joinPromptCTAContainer: {
    paddingHorizontal: 5,
  },
  joinPromptCTA: {
    fontWeight: "bold",
    color: "#22B2B8"
  },
  joinedContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 60,
  },
  joinedTitle: {
    fontSize: 30,
    color: '#22B2B8',
    textAlign: 'center',
    paddingBottom: 20,
  }
});

export const chatStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    // iOS workaround to position title as parent elements have absolute left/right position.
    left: Platform.select({
      ios: -30,
    }),
    marginRight: Platform.select({ 
      ios: -40, 
    })
  },
  headerImageContainer: {
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 35, 
    width: 35,
    borderWidth: 2.5,
    borderRadius: 20,
  },
  headerImage: {
    height: 30,
    width: 30,
    borderRadius: 12,
  },
  headerTitleContainer: {
    flex: 1,
  },
  headerTitle: {
    flexWrap: 'wrap',
    fontSize: Platform.select({
      ios: 16,
      android: 16
    }),
    fontWeight: '600',
    color: '#333333',
  },
  headerTitleNoIcon: {
    flex: 1, flexWrap: 'wrap',
    fontSize: Platform.select({
      ios: 16,
      android: 16
    }),
    fontWeight: '600',
    color: '#333333',
    marginLeft: Platform.select({
      ios: 20
    }),
    marginRight: Platform.select({
      android: 20
    })
  },
  imagePicker: {
    marginLeft: 10,
    alignSelf: 'center',
    paddingVertical: 6,
  },
  textInput: {
    lineHeight: 20,
    marginTop: Platform.select({ 
      ios: 0,
      android: 0,
      web: 0,
    }),
    marginBottom: Platform.select({ 
      ios: 8,
      android: 3,
      web: 3,
    }),
  },
  sendButton: {
    marginRight: 10,
    marginBottom: 3,
  },
  uploading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E5ECEE88',
  },
  h3: {
    marginBottom: 0,
    fontSize: 18,
  },
  loadingMsgs: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1, 
    transform: Platform.select({ 
      ios: [{ scaleY: -1 }],
      android: [{ scaleY: -1 }],
    }),
    marginBottom: Platform.select({
      web: 50,
    })
  },
  // web styling to render chat container
  chatContainer: {
    flex: Platform.select({ 
      ios: 1,
      android: 1,
    }),
    height: Platform.select({ 
      web: chatViewHeight,
    })
  },
})


