import { Platform } from 'react-native';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { storage } from '../Libs/Firebase';

// Upload to cloud storage
export async function uploadChatImage(blob, gid) {
  const uuid = uuidv4() // create uuid for saving image
  
  const ref = storage.ref('messages/'+gid+'/'+uuid+'.png')
  const snap = await ref.put(blob)
  const remoteUri = await snap.ref.getDownloadURL()
  
  if (Platform.OS !== 'web') blob.close() // now close blob to free up memory  - e.close is not a function for web
  return remoteUri
}
