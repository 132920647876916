import {useState, useEffect} from 'react';

import {db} from './Firebase';
import {useMemData} from './useMemData';

// fetch user groups when called or when member counts change
export const useUserGroups = (uid) => {

  const [userGroups, setUserGroups] = useState([])

  const memCounts = useMemData()

  useEffect(() => {
    // save signed up groups for user 
    const fetchUserGroups = () => {
      if (memCounts != undefined) { // only fetch if memCounts fetched
        
        db().ref('users/'+uid+'/groups')
        .on('value', snap => { 
          
          const userGroups = [] // reverts to [] when .on triggered

          const snapObj = (snap.val() != null) ? snap.val() : {}
          const userArr = Object.entries(snapObj);

          for (let i = 0; i < userArr.length; i++) {
            if (userArr[i][1].status != '0left') {  // group info in element [1]
              userGroups.push(userArr[i])
            }
            if (i == userArr.length - 1) {
              setUserGroups(userGroups)
            }
          }
        })
      }
    }

    return () => fetchUserGroups()

  }, [memCounts]) // only rerun if memCount changes?? not sure about this

    return userGroups

}

