import React, {useState} from 'react';
import { View, Text, SafeAreaView, 
  TextInput, TouchableOpacity, Alert } from 'react-native';

import firebase from 'firebase';
import { AuthStyles } from '../Styles/AuthStyles';

export default function PwdReset ({navigation}) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();

  // reset password and send alert to confirm, or prompt to reenter email. 
  const passwordReset = email => {
    firebase.auth().sendPasswordResetEmail(email)
    .then(function() {
      sendAlert();
    }).catch(function(error) {
      setError(error.message)
    });
  }

  const sendAlert = () =>
    Alert.alert(
      "Email sent",
      "Check your email to reset your password",
      [{ 
        text: "OK", 
        onPress: () => navigation.navigate('Login') 
      }],
      { cancelable: false }
    );

  const auth = navigation.getParam('auth')

  return (
    <SafeAreaView style={ AuthStyles.container }> 
          
        <Text style = { AuthStyles.H3 }>
          Forgot your Password?
        </Text> 

        <TextInput 
          style = { AuthStyles.textInput }
          autoCapitalize = "none"
          placeholder = "Email"
          placeholderTextColor = "#676767"
          onChangeText = {email => { 
            setEmail(email)
          }}
        />

        <View>
          { error && 
            <Text style = { AuthStyles.error }>
              {error}
            </Text>
          }
        </View>

        <TouchableOpacity 
          style = { AuthStyles.button } 
          onPress = {() => { passwordReset(email) }}
        >
          <Text style = { AuthStyles.buttonText }>
            Send password reset email
          </Text> 

        </TouchableOpacity>

        { auth !== true &&   
          <TouchableOpacity 
            style = { AuthStyles.otherOption }
            onPress = {() => navigation.navigate("Login")}
          >
            <Text style = { AuthStyles.otherText } >
              Back to Login
            </Text>
                
          </TouchableOpacity>
        }

    </SafeAreaView> 
  )
}