import React from 'react'; 
import {createAppContainer, createSwitchNavigator} from 'react-navigation';
import {createStackNavigator} from 'react-navigation-stack';

import {UserProvider} from './data/FetchData';
import AuthCheck from './src/Auth/AuthCheck';

import { SignupScreen } from './src/Auth/SignupScreen';
import Onboarding from './src/Auth/Onboarding';
import LoginScreen from './src/Auth/LoginScreen';
import PwdReset from './src/Auth/PwdReset';
import { TermsWebView, PrivacyWebView } from './src/Modules/WebViews';

import { pushCheck } from './src/pushCheck';
import { GroupList } from './src/GroupList';
import UserProfile from './src/UserProfile';
import ChatScreen from './src/ChatScreen';
import GroupInfo from './src/GroupInfo';
import { JoinGroup } from './src/JoinGroup';
import MemberProfile from './src/MemberProfile';

import { StatusBar, Platform } from 'react-native';
import Bugsnag from '@bugsnag/expo';

// Bugsnag is not compatible with web - manual setup https://docs.bugsnag.com/platforms/react-native/expo/manual-setup
// let ErrorBoundary
if (Platform.OS !== 'web') {
  Bugsnag.start();
  // ErrorBoundary = Bugsnag.getPlugin('react') //only for dev mode
}

// fixes status bar not showing on iOS
if (Platform.OS == 'ios') StatusBar.setBarStyle('dark-content', true)

const AppNavigator = createStackNavigator(
  {
    Group: {screen: GroupList},
    Profile: {screen: UserProfile},
    Join: {screen: JoinGroup},
    Chat: {screen: ChatScreen},
    GroupInfo: {screen: GroupInfo},
    MemberProfile: {screen: MemberProfile},
    PwdReset: {screen: PwdReset}, 
  },
  {
    initialRouteName: "Group",
    defaultNavigationOptions: {
      headerTintColor: '#22B2B8',
      headerTitleStyle: {
        color: '#333333',
        // numberOfLines: 2,
      },
    },
  }
)

// reversed signup/login to start with sign up first. 
const AuthNavigator = createSwitchNavigator(
  {
  Signup: {screen: SignupScreen},
  Onboarding: {screen: Onboarding},
  Login: {screen: LoginScreen},
  PwdReset: {screen: PwdReset},
  Terms: {screen: TermsWebView},
  Privacy: {screen: PrivacyWebView}
  },
  {
    headerMode: "none",
  }
)

// initialRouteName must always start with a switch navigator screen name
const AppContainer = createAppContainer(
  createSwitchNavigator(
    {
      AuthCheck: {screen: AuthCheck},
      pushCheck: {screen: pushCheck},
      App: AppNavigator, 
      Auth: AuthNavigator,
    },
    {
      initialRouteName: "AuthCheck"
    }
  )
);

const App = () => {
  return ( // bugsnag does not work on web
    <UserProvider>
      <AppContainer/>
    </UserProvider>
  )
}

export default App;
