import {useGroups} from '../Libs/useGroups';
import {useUserGroups} from '../Libs/useUserGroups';

// sort groups for showing to user + member counter
export function GroupData(uid) { // temp passing in uid
  const rawGroupData = useGroups(uid);
  const userGroups = useUserGroups(uid);

  // transform data to pull for ItemList
  function workingData() {
    const workingData = [];

    // check if user is in group, if so, write userGroups, else write rawGroupData
    for (let i = 0; i < rawGroupData.length; i++) {
      let userInGroup = false
      for (let j = 0; j < userGroups.length; j++) {
        // if user registered/active for gid, gids in [0] element
        if (rawGroupData[i][0] === userGroups[j][0]) { 
          workingData.push(userGroups[j][1])
          userInGroup = true
        }
      }     
      // if user NOT reg/active for gid
      if (userInGroup == false) workingData.push(rawGroupData[i][1]);
    }
    return workingData
  }  
  
  const wkData = workingData();
  
  // split data into 3 lists
  const activeData = [];
  const registeredData = [];
  const browseData = [];
  
  if (wkData.length != 0) { // execute if wkData loaded
    for (let i = 0; i < wkData.length; i++) {
      const status = wkData[i].status

      // push to new list section based on user status in group
      if (status === "1active") activeData.push(wkData[i])
      else if (status === "2registered") registeredData.push(wkData[i])
      // case where user is not joined, and group is NOT full
      else if (status !== "Full") browseData.push(wkData[i]);
    }  
    // sort lists
    activeData.sort((a, b) => b["newestTS"] - a["newestTS"] );
    registeredData.sort((a, b) => b["newestTS"] - a["newestTS"] );
    browseData.sort((a, b) => a["gid"] - b["gid"] );
  }

  return [
    activeData, 
    registeredData, 
    browseData,
  ]
}
