import { Platform } from 'react-native';

import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import Constants from 'expo-constants';
import { storage } from "../Libs/Firebase"

// iOS request camera roll permission
export async function cameraRollPermission() {
  if (Constants.platform.ios) {
    const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
    if (status !== 'granted') {
      alert('Sorry, we need camera roll permissions to make this work!');
    }
  }
};

// Upload image to app, Expo ImagePicker gets file URI 
export async function pickImage() { 
  const canEditIfAndroid = (Platform.OS == 'android') ? true : false
  try {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: canEditIfAndroid,
      // aspect: [4, 3], //iOS always defaults to square
    })
    if (!result.cancelled) {
      return(result)
    }
  } catch (E) {
    console.log(E);
    throw E;
  }
}

// Shrink image to 500px wide if needed
export async function resize(image) {
  let uri = image.uri
  const width = image.width

  // iOS has better compression, so set lower resizeWidth
  const resizeWidth = (Platform.OS === 'ios') ? 500 : 900
  if (width < resizeWidth) return uri

  const resizedImage = await ImageManipulator.manipulateAsync(
    uri, 
    [{ resize: {width: resizeWidth} }], 
    { compress: 0.8 }
  )
  return uri = resizedImage.uri
}

// Create a blob that can be read by Cloud Storage - see https://github.com/expo/expo/issues/2402
export async function uriToBlob(imageUri) {
  const blob = await new Promise((resolve, reject) => {
    
    const xhr = new XMLHttpRequest()
    xhr.onload = function() {
      resolve(xhr.response); // when BlobModule finishes reading, resolve with the blob
    }
    xhr.onerror = function() {
      reject(new TypeError('Network request failed')) // error occurred, rejecting
    }

    xhr.responseType = 'blob' // use BlobModule's UriHandler
    xhr.open('GET', imageUri, true) // fetch the blob from uri in async mode
    xhr.send(null); // no initial data
  })
  return blob
}

// Upload to cloud storage
export async function uploadToStorage(uid, blob) {
  const ref = storage.ref('users/'+uid+'/profile.png') // always upload as pngs to save storage. 
  const snap = await ref.put(blob)
  const remoteUri = await snap.ref.getDownloadURL()
  
  if (Platform.OS !== 'web') blob.close() // now close blob to free up memory
  return remoteUri
}


// errorhandling, loading indicator

