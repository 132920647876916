import React, {useState, useEffect} from 'react';

import firebase from 'firebase';
import { AppLoading } from 'expo';

// create context
const UserContext = React.createContext({
  user: null,
})

// Create a "custom" hook that monitors the firebase authentication state. 
// This hook will create state which uses a useState callback to 
// determine whether a user session already exists. 
// The callback is a useful way to initialize state with a value 
// only upon the first mount of a component.
export const useAuthUser = () => {

  const [user, setUser] = useState(() => { 
    const user = firebase.auth().currentUser 
    return { initializing: !user, user, }
  });

  // the onChange function will be called with the current authentication state.
  function onChange(user) {
    setUser({ initializing: false, user })
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => {
        unsubscribe();
    }
  }, []);
  return user; // return authenticated user
}

// create a hook that allows us to access our context.
export const useSession = () => {
    const { user } = useContext(UserContext)
    return user
};

// use this hook at the top level of our app and use our context provider
// to supply the user session to child components. 
function UserProvider (props) {
  const { initializing, user } = useAuthUser()

  if (initializing) {
    return (
      <AppLoading/>
    )
  }

  return (
    <UserContext.Provider value = {{ 
          user 
        }}
    > 
        {props.children}
    </UserContext.Provider> 
  )
}

export {UserContext, UserProvider};

