let displayTime

export const dateFormat = (msgDate) => {
  
  // save date for today
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() 
  const day = now.getDate()
  
  // save 12AM today and yesterday in local time to compare
  const today12AM = new Date(year, month, day, 0, 0, 1) 
  const msPerDay = 86400000 
  const yesterday12AM = today12AM - msPerDay
  
  // show date || yesterday || time
  if (msgDate < yesterday12AM) {
    // display msgDate
    const msgYear = msgDate.getFullYear() - 2000 // only show last 2 digits
    const msgMonth = msgDate.getMonth() + 1 // months start at 0
    const msgDay = msgDate.getDate()

    return displayTime = `${msgMonth}/${msgDay}/${msgYear}`
  }
  if (msgDate < today12AM) return displayTime = 'Yesterday'
  return timeAMPM (msgDate)
}

const timeAMPM = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()

  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = (hours == 0) ? 12 : hours // the hour '0' should be '12'
  if (minutes < 10) minutes = '0'+minutes 
  return displayTime = hours + ':' + minutes + ' ' + ampm
}

// yesterday 12am: 1588429801000