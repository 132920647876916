import {useState, useEffect} from 'react';

import {db} from './Firebase';
import {useMemData} from './useMemData';

// fetch groups (where user has not left) when called or when member counts change
export const useGroups = uid => {

  const [groups, setGroups] = useState([])

  const memCounts = useMemData()

  const fetchGroups = () => {
    if (memCounts != undefined) { // only fetch if members in group

      db().ref(`groups/`).on('value', snap => { // computationally expensive and will always run?
        let userLeft, gidLeft
        let groupObj = snap.val()
        
        // for each group, if user has left, delete from groups to show in Browse Topics 
        snap.forEach(childSnap => {
          const {membersLeft} = childSnap.val() // destructure - membersLeft from thisGroup
          userLeft = false
          
          // search membersLeft for uid
          if(membersLeft != undefined) {
            userLeft = (membersLeft.hasOwnProperty(uid)) ? true : false
          }
          // delete prop in each group that user was in
          if (userLeft == true) {
            gidLeft = childSnap.key
            delete groupObj[`${gidLeft}`]
          }
        })
        
        const groups = Object.entries(groupObj)
        // return groups

      // }).then(groups => 
        return setGroups(groups) })
        // )
    };
  }

  useEffect(() => {
    
    return () => fetchGroups()

  }, [memCounts]) // only rerun if memCount changes?? not sure about this

  return groups

}