import Constants from 'expo-constants';

// check if dev or standalone + staging device 
export const CheckEnv = () => {
  let DevOrStaging = (Constants.appOwnership === 'expo' || Constants.manifest.releaseChannel === 'staging')
  // if web, set staging if on localhost OR staging URL
  if (Constants.platform.web) DevOrStaging = (location.hostname === "localhost")
  // console.log(DevOrStaging + ' is if dev or staging')
  // console.log(location.hostname + ' location.hostname')
  return DevOrStaging
}